import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import {
  Checkbox,
  TextField,
  Snackbar,
  CircularProgress,
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Delete from "@material-ui/icons/Delete";
import moment from "moment";
import { eventEligibility, eventFieldType } from "../../../utils/constant";
import { useSelector } from "react-redux";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../partials/content/Portlet";
import { createNewEvent } from "../../../api/event";
import { Editor } from "@tinymce/tinymce-react";

export default function NewEventPage() {
  const history = useHistory();
  const editorRef = React.useRef(null);
  const editorNoteRef = React.useRef(null);
  const { branch } = useSelector(({ auth }) => auth.user);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [error, setError] = useState("Registartion Failed!");

  // dynamic form fields
  const [customFields, setCustomFields] = useState({});

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };
  const handleCloseFailure = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFailure(false);
  };

  const addField = (label, type, options) => {
    const fieldName = `field${Object.keys(customFields).length + 1}`;
    setCustomFields({
      ...customFields,
      [fieldName]: { label, type, options, disabled: false },
    });
  };

  const deleteCustomField = (fieldName) => {
    const newCustomFields = { ...customFields };
    delete newCustomFields[fieldName];
    setCustomFields(newCustomFields);
  };

  const renderCustomField = (customField) => {
    const { label, options, type, disabled } = customField;
    if (type === "Text") {
      return (
        <div className="col-12">
          <TextField
            label={label}
            margin="normal"
            type="text"
            variant="outlined"
            disabled={disabled}
            fullWidth
          />
        </div>
      );
    } else if (type === "Dropdown") {
      return (
        <div className="col-12">
          <Autocomplete
            options={options.split(",")}
            disabled={disabled}
            getOptionLabel={(options) => options}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
      );
    } else if (type === "Checkbox") {
      return (
        <div className="col-12">
          <FormGroup>
            {options.split(",").map((val) => (
              <FormControlLabel
                disabled={disabled}
                key={val}
                control={<Checkbox />}
                label={val}
              />
            ))}
          </FormGroup>
        </div>
      );
    } else if (type === "Radio") {
      return (
        <div className="col-12">
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              {options.split(",").map((val) => (
                <FormControlLabel
                  disabled={disabled}
                  key={val}
                  value={val}
                  control={<Radio />}
                  label={val}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      );
    } else if (type === "Switch") {
      return (
        <div className="col-12">
          <FormGroup>
            <FormControlLabel
              disabled={disabled}
              control={<Switch defaultChecked />}
              label={label}
            />
          </FormGroup>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Portlet>
      <PortletHeader title="Maklumat Peristiwa">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={success}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
        >
          <MuiAlert onClose={handleCloseSuccess} severity="success">
            Registration Success!
          </MuiAlert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={failure}
          autoHideDuration={6000}
          onClose={handleCloseFailure}
        >
          <MuiAlert onClose={handleCloseFailure} severity="error">
            {error}
          </MuiAlert>
        </Snackbar>
      </PortletHeader>
      <PortletBody fluid={true}>
        <Formik
          initialValues={{
            startDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
            endDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
            branch,
            limit: 100,
            fee: 0,
            memberPrice: 0,
            billingId: "w7tzjsd98",
            customFieldType: "Text",
            customFieldQuestion: "",
            customFieldOptions: "",
          }}
          style={{ maxWidth: 400 }}
          onSubmit={(values, { setSubmitting }) => {
            delete values.customFieldQuestion;
            delete values.customFieldOptions;
            delete values.customFieldType;
            const modfiedValues = {
              ...values,
              customFields,
            };
            createNewEvent(JSON.stringify(modfiedValues, null, 2))
              .then(() => {
                setSuccess(true);
                setSubmitting(false);
                history.push("/event/list?status=0");
              })
              .catch((err) => {
                setError(err.message || "Failed");
                setFailure(true);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row p-3">
                <div className="col-md-12 col-sm-12">
                  <TextField
                    label="Nama"
                    margin="normal"
                    type="text"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </div>
                <div className="col-md-12 col-sm-12">
                  <Editor
                    apiKey={process.env.REACT_APP_TINY_EDITOR_APIKEY}
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    value={values.description}
                    onEditorChange={(val) => {
                      setFieldValue("description", val);
                    }}
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <TextField
                    label="Waktu Bermula"
                    margin="normal"
                    fullWidth
                    type="datetime-local"
                    name="startDate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.startDate}
                    variant="outlined"
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <TextField
                    label="Waktu Tamat"
                    margin="normal"
                    fullWidth
                    type="datetime-local"
                    name="endDate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.endDate}
                    variant="outlined"
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <TextField
                    label="Jumlah Peserta"
                    margin="normal"
                    name="limit"
                    type="number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.limit}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <TextField
                    label="Yuran Penyertaan (RM)"
                    margin="normal"
                    name="fee"
                    type="number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.fee}
                    variant="outlined"
                    required
                    fullWidth
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <TextField
                    label="Yuran Untuk Ahli (RM)"
                    margin="normal"
                    name="memberPrice"
                    type="number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.memberPrice}
                    variant="outlined"
                    required
                    fullWidth
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <TextField
                    label="Billing Id"
                    margin="normal"
                    name="billingId"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.billingId}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <div className="col-md-9 col-sm-12">
                  <TextField
                    label="Tempat"
                    margin="normal"
                    name="venue"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.venue}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </div>
                <div className="col-md-3 col-sm-12">
                  <Autocomplete
                    options={eventEligibility}
                    getOptionLabel={(options) => options.title}
                    value={eventEligibility[values.eligibility || 0]}
                    onChange={(_e, val) =>
                      setFieldValue("eventEligibility", val ? val.value : 0)
                    }
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="eligibility"
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="col-md-12 col-sm-12">
                  <p className="mt-2">
                    Maklumat untuk peserta selepas mendaftar
                  </p>
                  <Editor
                    apiKey={process.env.REACT_APP_TINY_EDITOR_APIKEY}
                    onInit={(evt, editor) => (editorNoteRef.current = editor)}
                    value={values.registrationNote}
                    onEditorChange={(val) => {
                      setFieldValue("registrationNote", val);
                    }}
                    init={{
                      height: 100,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>
                <p className="mt-4 mb-4">Custom Fields (Sekiranya perlu)</p>
                {Object.keys(customFields).map((key, index) => (
                  <div className="row mb-4" key={key}>
                    <div className="col-12">
                      <h6>
                        {index + 1}. {customFields[key].label}
                        <Delete
                          style={{ marginLeft: "7px" }}
                          onClick={() => deleteCustomField(key)}
                        />
                      </h6>
                    </div>
                    {renderCustomField(customFields[key])}
                  </div>
                ))}
                <div className="col-3">
                  <Autocomplete
                    options={eventFieldType}
                    getOptionLabel={(options) => options.title}
                    value={eventFieldType[values.customFieldType || 0]}
                    onChange={(_e, val) =>
                      setFieldValue("customFieldType", val ? val.value : "text")
                    }
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Jenis"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="col-5">
                  <TextField
                    label="Soalan"
                    margin="normal"
                    name="customFieldQuestion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customFieldQuestion}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <div className="col-4">
                  <TextField
                    label="Pilihan (Pisahkan dengan koma)"
                    margin="normal"
                    name="customFieldOptions"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.customFieldOptions}
                    variant="outlined"
                    fullWidth
                    disabled={
                      values.customFieldType === "Text" ||
                      values.customFieldType === "Switch"
                    }
                  />
                </div>
                <div className="mt-2 mb-10">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      addField(
                        values.customFieldQuestion,
                        values.customFieldType,
                        values.customFieldOptions
                      )
                    } // Set a default type or use the selected type from the dropdown
                  >
                    Tambah Field
                  </Button>
                </div>
                <div className="col-md-12 col-sm-12 text-center mb-5 mt-3">
                  {!isSubmitting ? (
                    <Button
                      type="submit"
                      variant="contained"
                      className="btn btn-lg btn-elevate btn-label-brand"
                    >
                      Tambah Baru
                    </Button>
                  ) : (
                    <CircularProgress />
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </PortletBody>
    </Portlet>
  );
}
