/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function StickyToolbar() {
  const handleContactUs = () => {
    if (typeof window !== "undefined") {
      window.FreshworksWidget("open");
    }
  };

  return (
    <>
      <ul className="kt-sticky-toolbar" style={{ marginTop: "30px" }}>
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="layout-tooltip">Hubungi Kami</Tooltip>}
        >
          <li
            className="kt-sticky-toolbar__item kt-sticky-toolbar__item--brand"
            data-placement="left"
          >
            <a onClick={handleContactUs}>
              <i className="flaticon-chat" />
            </a>
          </li>
        </OverlayTrigger>
      </ul>
    </>
  );
}
