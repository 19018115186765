export default {
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      {
        title: "Laman Utama",
        access: [0, 1, 3],
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "dashboard",
        bullet: "dot",
      },
      { section: "Ahli", access: [0, 1] },
      {
        title: "Senarai Ahli",
        access: [0, 1],
        root: true,
        icon: "flaticon2-indent-dots",
        bullet: "dot",
        submenu: [
          { title: "Belum Bayar", page: "member/list?status=0" },
          { title: "Baru", page: "member/list?status=1" },
          { title: "Aktif", page: "member/list?status=2" },
          { title: "Alumni", page: "member/list?status=3" },
          { title: "Tidak diluluskan", page: "member/list?status=4" },
          { title: "Tamat Tempoh", page: "member/list?status=5" },
          { title: "Semua", page: "member/list?status=undefined" },
        ],
      },
      {
        title: "Tambah Baru",
        access: [0],
        root: true,
        icon: "flaticon2-add-1",
        page: "member/new",
      },
      {
        title: "Tamat Keahlian",
        access: [0],
        root: true,
        icon: "flaticon2-cancel",
        page: "member/expired",
      },
      { section: "Rakan GEMA", access: [0, 1] },
      {
        title: "Senarai Rakan GEMA",
        access: [0, 1],
        root: true,
        icon: "flaticon2-indent-dots",
        bullet: "dot",
        page: "associate/list",
      },
      { section: "Peristiwa", access: [0, 1, 3] },
      {
        title: "Senarai Peristiwa",
        access: [0, 1],
        root: true,
        icon: "flaticon2-indent-dots",
        bullet: "dot",
        submenu: [
          { title: "Baru", page: "event/list?status=0" },
          { title: "Tutup", page: "event/list?status=3" },
          { title: "Aktif", page: "event/list?status=1" },
          { title: "Tamat", page: "event/list?status=2" },
          { title: "Semua", page: "event/list?status=undefined" },
        ],
      },
      {
        title: "Senarai Peristiwa",
        access: [3],
        root: true,
        icon: "flaticon2-indent-dots",
        bullet: "dot",
        submenu: [{ title: "Baru", page: "event/list?status=0" }],
      },
      {
        title: "Tambah Baru",
        access: [0, 1],
        root: true,
        icon: "flaticon2-add-1",
        page: "event/new",
      },
      { section: "Pengurusan", access: [0] },
      {
        title: "Admin & Staff",
        access: [0],
        root: true,
        bullet: "dot",
        submenu: [
          { title: "Senarai Admin", page: "manage/users/admins" },
          { title: "Senarai Staff", page: "manage/users/staffs" },
          { title: "Senarai Manager", page: "manage/users/managers" },
          { title: "Tambah Baru", page: "manage/users/new" },
        ],
        icon: "flaticon2-browser-2",
      },
      {
        title: "Laporan (Carta)",
        root: true,
        access: [0, 1],
        icon: "flaticon2-pie-chart",
        page: "manage/charts",
      },
      {
        title: "Log Aktiviti",
        root: true,
        access: [0],
        icon: "flaticon2-settings",
        page: "manage/activity",
      },
      { section: "Aplikasi", access: [0, 1] },
      {
        title: "Multilinks",
        access: [0, 1],
        root: true,
        icon: "flaticon2-indent-dots",
        bullet: "dot",
        submenu: [
          { title: "Senarai", page: "app/multilink/list" },
          { title: "Tambah Baru", page: "app/multilink/new" },
        ],
      },
      {
        title: "Shorten Link",
        root: true,
        access: [0, 1],
        icon: "flaticon2-size",
        page: "app/shorturl",
      },
      {
        title: "Attendance",
        root: true,
        access: [0, 1, 3],
        icon: "flaticon2-correct",
        bullet: "dot",
        submenu: [
          { title: "Senarai", page: "app/attendance/list" },
          { title: "Tambah Baru", page: "app/attendance/new" },
        ],
      },
      { section: "Sigma Gema Version 2.0", access: [0, 1] },
    ],
  },
};
