import React, { useState } from "react";
import { getMembersLogs } from "../../../api/member";
import moment from "moment";

export default function ActivityPage() {
  const [issues, setIssues] = useState([]);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = await getMembersLogs();
        if (data) {
          setIssues(data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <div className="card card-xl-stretch">
        {/* begin::Header */}
        <div className="card-header border-0">
          <h3 className="card-title fw-bolder text-dark">Pendaftaran Ahli</h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body">
          {issues.length > 0 &&
            issues.map((i) => (
              <>
                {/* begin::Item */}
                <div
                  className={`d-flex align-items-center ${
                    i.title.includes("approved")
                      ? "bg-light-success"
                      : "bg-light-danger"
                  } rounded p-1 m-1`}
                >
                  {/* begin::Title */}
                  <div className="flex-grow-1">
                    <p className="text-gray-800 text-hover-primary pt-1">
                      <b>{i.title.split("was")[0]}</b>
                      was
                      {i.title.split("was")[1] &&
                        i.title.split("was")[1].replace(/([^\s]+$)/, "")}
                      <b>{i.title.split(" ").pop()}</b>
                    </p>
                  </div>
                  {/* end::Title */}
                  {/* begin::Lable */}
                  <p className="text-muted fw-bold">
                    {moment.utc(i.firstSeen).format("MMMM Do YYYY, h:mm:ss a")}
                  </p>
                  {/* end::Lable */}
                </div>
                {/* end::Item */}
              </>
            ))}
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
