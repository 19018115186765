import axios from "axios";

const token = process.env.REACT_APP_MAIN_API_TOKEN;

const memberAxios = axios.create({
    baseURL: process.env.REACT_APP_MAIN_API_URL,
    headers: {
        "X-Request-Agent": "gema-dashboard",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
    }
});

export async function getAllAssociates() {
    try {
        const { data } = await memberAxios.get("associate");
        return data;
    } catch (error) {
        console.log(error);
    }
}

export async function getAssociatesByBranch(br) {
    try {
        const { data } = await memberAxios.get(`/associate/filtered?branch=${br}`);
        return data;
    } catch (error) {
        console.log(error);
    }
}

export async function getAssociateById(qs) {
    try {
        const { data } = await memberAxios.get(`/associate/details?id=${qs}`);
        return data;
    } catch (error) {
        console.log(error);
    }
}

export async function updateAssociate(id, associateDto) {
    try {
        const response = await memberAxios.put(`/associate/${id}`, associateDto);
        if (response.status === 200) {
            return true;
        }
        return false;
    } catch (error) {
        throw error.response.data;
    }
}