import axios from "axios";

const token = process.env.REACT_APP_MAIN_API_TOKEN;

const memberAxios = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_MAIN_API_URL
      : process.env.REACT_APP_MAIN_API_URL_LOCAL,
  headers: {
    "X-Request-Agent": "gema-dashboard",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export async function getAllMembersData() {
  try {
    const { data } = await memberAxios.get("dashboard/allMembers");
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getNewMembersData() {
  try {
    const { data } = await memberAxios.get("dashboard/newMembers");
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getActiveMembersData() {
  try {
    const { data } = await memberAxios.get("dashboard/activeMembers");
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getAlumniMembersData() {
  try {
    const { data } = await memberAxios.get("dashboard/alumniMembers");
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getRevenueData() {
  try {
    const { data } = await memberAxios.get("dashboard/revenue");
    return data;
  } catch (error) {
    console.log(error);
  }
}
