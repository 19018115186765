import React from "react";
import { Link } from "react-router-dom";
import { Grid, makeStyles, Paper } from "@material-ui/core";
import { useSelector } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import MaterialTable from "material-table";
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import {
  getAllAssociates,
  getAssociatesByBranch,
} from "../../../api/associate";

import exportToExcel from "../../../utils/exportToExcel";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function MemberListPage(props) {
  const { userType, branch } = useSelector(({ auth }) => auth.user);
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const br = userType === 0 ? undefined : branch;

  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = br
          ? await getAssociatesByBranch(br)
          : await getAllAssociates();
        if (data) {
          setRows(data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [props.location.search, br]);

  const columns = [
    {
      field: "id",
      title: "No",
      width: 50,
      render: (rowData) => <p>{rows.indexOf(rowData) + 1}</p>,
    },
    { field: "fullname", title: "Nama", width: 300 },
    {
      field: "associateId",
      title: "No Pendaftaran",
    },
    {
      field: "branch",
      title: "Cawangan Gema",
      render: (rowData) => {
        if (rowData.branch) {
          return rowData.branch.replace("_", " ");
        }
        return null;
      },
    },
    {
      field: "email",
      title: "E-mel",
    },
    {
      field: "phone",
      title: "No Tel",
    },
    {
      field: "id",
      title: "",
      render: (rowData) => (
        <Link to={`/associate/edit?id=${rowData.id}`}>
          <EditIcon color="action" />
        </Link>
      ),
    },
  ];

  return (
    <Portlet>
      <PortletBody fluid={true}>
        <Grid className="p-3" container spacing={3}>
          <Grid item xs={12}>
            <span className="kt-section__sub">
              Senarai di bawah adalah rakan gema yang telah mendaftar melalui
              sistem sigma.
            </span>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.root}>
              <MaterialTable
                columns={columns}
                data={rows}
                title={`Bilangan: ${rows.length}`}
                options={{
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportCsv: (columns, data) => {
                    const st = data.map((associate) => {
                      return {
                        No_Ahli: associate.associateId,
                        Cawangan: associate.branch,
                        Nama: associate.fullname,
                        Emel: associate.email,
                        Facebook: associate.fbProfile,
                        Instagram: associate.iGProfile,
                        No_IC: associate.nationalId,
                        No_Tel: associate.phone,
                        Titktok: associate.tikTokProfile,
                      };
                    });
                    exportToExcel(st);
                  },
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </PortletBody>
    </Portlet>
  );
}
