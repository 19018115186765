import React from "react";
import { Route, Switch } from "react-router-dom";
import EditAssociatePage from "./EditAssociatePage";
import ListAssociatePage from "./ListAssociatePage";

export default function AssociatePage() {
    return (
        <Switch>
            <Route
                path="/associate/edit"
                component={EditAssociatePage}
            />
            <Route
                path="/associate/list"
                component={ListAssociatePage}
            />
        </Switch>
    );
}
