import axios from "axios";

const urlAxios = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_MAIN_API_URL
      : process.env.REACT_APP_MAIN_API_URL_LOCAL,
});

export async function createUrl(urlDto) {
  try {
    const { data } = await urlAxios.post(`/multilink`, urlDto);
    return data;
  } catch (error) {
    const { response } = error;
    if (response) {
      throw new Error(response.data.message);
    }
    throw new Error(error);
  }
}

export async function getUrlsByUser(id) {
  try {
    const { data } = await urlAxios.get(`/multilink/user/${id}`);
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getUrlsById(id) {
  try {
    const { data } = await urlAxios.get(`/multilink/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function updateUrl(id, urlDto) {
  try {
    const response = await urlAxios.patch(`/multilink/${id}`, urlDto);
    return response.status === 200;
  } catch (error) {
    console.log(error);
  }
}

export async function getUrlByPath(id) {
  try {
    const { data } = await urlAxios.get(`/multilink/path/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function removeUrl(id) {
  try {
    const response = await urlAxios.delete(`/multilink/${id}`);
    return response.status === 200;
  } catch (error) {
    console.log(error);
  }
}
