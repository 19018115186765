import React from "react";
import {
  Grid,
  makeStyles,
  Paper,
  Switch,
  Button,
  TextField,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import MaterialTable from "material-table";
import DeleteIcon from "@material-ui/icons/Delete";
import MuiAlert from "@material-ui/lab/Alert";
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import {
  createUrl,
  getUrlsByUser,
  removeUrl,
  updateUrl,
} from "../../../api/shorturl";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function ShorturlPage(props) {
  const { id, name } = useSelector(({ auth }) => auth.user);
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [success, setSuccess] = React.useState(false);
  const [failure, setFailure] = React.useState(false);
  const [error, setError] = React.useState("Link Creation Failed!");
  const [refreshKey, setRefreshKey] = React.useState(0);

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess(false);
  };
  const handleCloseFailure = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFailure(false);
  };

  const togglePublished = async (id, value) => {
    const updated = await updateUrl(id, { published: value });
    if (updated) {
      setRefreshKey((oldKey) => oldKey + 1);
    }
    setError("Something when wrong when updating");
  };

  const deleteUrl = async (id) => {
    const removed = await removeUrl(id);
    if (removed) {
      setRefreshKey((oldKey) => oldKey + 1);
    }
    setError("Something when wrong when deleting");
  };

  React.useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await getUrlsByUser(id);
        if (data.urls) {
          setRows(data.urls);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [id, refreshKey]);

  const columns = [
    {
      field: "id",
      title: "No",
      width: 75,
      render: (rowData) => <p>{rows.indexOf(rowData) + 1}</p>,
    },
    {
      field: "shorten",
      title: "Pautan Pendek",
      render: (rowData) => (
        <>
          <a
            href={`https://sigmagema.com/r/${rowData.shorten}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {rowData.shorten}
          </a>
        </>
      ),
    },
    { field: "original", title: "Pautan Asal", width: 500 },
    {
      field: "id",
      title: "Pautan Aktif",
      render: (rowData) => (
        <>
          <Switch
            checked={rowData.published}
            onClick={() => togglePublished(rowData.id, !rowData.published)}
          />
        </>
      ),
    },
    {
      field: "id",
      title: "",
      render: (rowData) => (
        <>
          <DeleteIcon color="action" onClick={() => deleteUrl(rowData.id)} />
        </>
      ),
    },
  ];

  return (
    <>
      <Portlet>
        <PortletBody fluid={true}>
          <Formik
            initialValues={{}}
            onSubmit={(values, { setSubmitting }) => {
              createUrl({
                userId: id,
                userName: name,
                original: values.original,
                shorten: values.shorten,
              })
                .then(() => {
                  setSuccess(true);
                  setSubmitting(false);
                  setRefreshKey((oldKey) => oldKey + 1);
                })
                .catch((err) => {
                  setError(err.message || "Failed");
                  setFailure(true);
                  setSubmitting(false);
                });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form className={classes.root} onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-3 col-sm-12">
                    <TextField
                      label="Pautan Pendek"
                      margin="normal"
                      type="text"
                      name="shorten"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.shorten}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <TextField
                      label="Pautan Asal"
                      margin="normal"
                      type="text"
                      name="original"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.original}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </div>
                  <div className="col-md-3 col-sm-12">
                    {!isSubmitting ? (
                      <Button
                        type="submit"
                        variant="contained"
                        className="btn btn-lg btn-label-brand mt-5"
                      >
                        Tambah Pautan
                      </Button>
                    ) : (
                      <CircularProgress />
                    )}
                  </div>
                </div>
              </form>
            )}
          </Formik>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={success}
            autoHideDuration={6000}
            onClose={handleCloseSuccess}
          >
            <MuiAlert onClose={handleCloseSuccess} severity="success">
              Link Successfully Created!
            </MuiAlert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={failure}
            autoHideDuration={6000}
            onClose={handleCloseFailure}
          >
            <MuiAlert onClose={handleCloseFailure} severity="error">
              {error}
            </MuiAlert>
          </Snackbar>
        </PortletBody>
      </Portlet>
      <Portlet>
        <PortletBody fluid={true}>
          <Grid className="p-3" container spacing={3}>
            <Grid item xs={12}>
              <span className="kt-section__sub">
                Senarai pautan yang telah dipendekkan
              </span>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.root}>
                <MaterialTable
                  columns={columns}
                  data={rows}
                  title={`Bilangan pautan: ${rows.length}`}
                />
              </Paper>
            </Grid>
          </Grid>
        </PortletBody>
      </Portlet>
    </>
  );
}
