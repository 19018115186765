import axios from "axios";

const userAxios = axios.create({
  baseURL: process.env.REACT_APP_IDENTITY_SERVICE_URL,
});

export async function getAdmin(authToken) {
  try {
    const { data } = await userAxios.get(`/identity/admins`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getStaff(authToken) {
  try {
    const { data } = await userAxios.get(`/identity/staffs`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getUsers(authToken) {
  try {
    const { data } = await userAxios.get(`/identity`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function deleteStaff(authToken, id) {
  try {
    const { data } = await userAxios.delete(`/identity/staffs/${id}/delete`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getEventManager(authToken) {
  try {
    const { data } = await userAxios.get(`/identity/eventmanager`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function deleteEventManager(authToken, id) {
  try {
    const { data } = await userAxios.delete(
      `/identity/eventmanager/${id}/delete`,
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}
