import React from "react";
import { Grid, makeStyles, Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { getEventManager, deleteEventManager } from "../../../../api/user";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function ManagerPage(props) {
  const classes = useStyles();
  const [staffRows, setStaffRows] = React.useState([]);
  const [load, setLoad] = React.useState(false);

  const authToken = useSelector(({ auth }) => auth.authToken);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = await getEventManager(authToken);
        if (data) {
          setStaffRows(data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [authToken, load]);

  const columns = [
    {
      field: "id",
      title: "No",
      width: 50,
      render: (rowData) => <p>{staffRows.indexOf(rowData) + 1}</p>,
    },
    { field: "name", title: "Nama", width: 300 },
    {
      field: "email",
      title: "E-mel",
    },
    { field: "branch", title: "Cawangan Gema" },
  ];

  return (
    <>
      <Portlet>
        <PortletBody fluid={true}>
          <Grid className="p-3" container spacing={3}>
            <Grid item xs={12}>
              <span className="kt-section__sub">
                Senarai pengurus yang aktif untuk menguruskan peristiwa.
              </span>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.root}>
                <MaterialTable
                  columns={columns}
                  data={staffRows}
                  title={`Senarai Staff`}
                  actions={[
                    {
                      icon: "delete",
                      tooltip: "Delete User",
                      onClick: async (event, rowData) => {
                        await deleteEventManager(authToken, rowData.id);
                        setLoad(!load);
                      },
                    },
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportCsv: (columns, data) => {
                      alert(
                        "Export not supported yet " + data.length + " rows"
                      );
                    },
                  }}
                />
              </Paper>
            </Grid>
          </Grid>
        </PortletBody>
      </Portlet>
    </>
  );
}
