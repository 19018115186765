import React from "react";
import { Formik } from "formik";
import {
  TextField,
  Snackbar,
  CircularProgress,
  Button,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../partials/content/Portlet";
import { createUser } from "../../../api/auth";

export default function FeePage(props) {
  const [success, setSuccess] = React.useState(false);
  const [failure, setFailure] = React.useState(false);
  const [error, setError] = React.useState("Registration Failed!");

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess(false);
  };
  const handleCloseFailure = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFailure(false);
  };

  return (
    <>
      <Portlet>
        <PortletHeader title="Amaun yuran yang dikenakan" />
        <PortletBody fluid={true}>
          <Formik
            initialValues={{}}
            onSubmit={(values, { setSubmitting }) => {
              createUser(values)
                .then(() => {
                  setSuccess(true);
                  setSubmitting(false);
                })
                .catch((err) => {
                  setError(err.message || "Failed");
                  setFailure(true);
                  setSubmitting(false);
                });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <TextField
                      label="Yuran Asas"
                      margin="normal"
                      type="text"
                      name="basicFee"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.basicFee}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </div>

                  <div className="col-12">
                    <TextField
                      label="Yuran Penuh"
                      margin="normal"
                      type="text"
                      name="fullFee"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.fullFee}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </div>

                  <div className="col-md-12 col-sm-12">
                    {!isSubmitting ? (
                      <Button
                        type="submit"
                        variant="contained"
                        className="btn btn-lg btn-elevate btn-label-brand"
                      >
                        Kemaskini Yuran
                      </Button>
                    ) : (
                      <CircularProgress />
                    )}
                  </div>
                </div>
              </form>
            )}
          </Formik>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={success}
            autoHideDuration={6000}
            onClose={handleCloseSuccess}
          >
            <MuiAlert onClose={handleCloseSuccess} severity="success">
              Invitation Sent!
            </MuiAlert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={failure}
            autoHideDuration={6000}
            onClose={handleCloseFailure}
          >
            <MuiAlert onClose={handleCloseFailure} severity="error">
              {error}
            </MuiAlert>
          </Snackbar>
        </PortletBody>
      </Portlet>
    </>
  );
}
