import React from "react";
import { useSelector } from "react-redux";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";

export default function MenuList(props) {
  const user = useSelector(({ auth }) => auth.user);

  const { currentUrl, menuConfig, layoutConfig } = props;

  return menuConfig.aside.items.map((child, index) => {
    const { access } = child;
    return (
      <React.Fragment key={`menuList${index}`}>
        {child.section && access && access.includes(user.userType) && (
          <MenuSection item={child} />
        )}
        {child.separator && <MenuItemSeparator item={child} />}
        {child.title && access && access.includes(user.userType) && (
          <MenuItem
            item={child}
            currentUrl={currentUrl}
            layoutConfig={layoutConfig}
          />
        )}
      </React.Fragment>
    );
  });
}
