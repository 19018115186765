import React from "react";
import { Route, Switch } from "react-router-dom";
import ShorturlPage from "./ShorturlPage";
import MultiLinkList from "./MultiLinks/List";
import MultiLinkEdit from "./MultiLinks/Edit";
import MultiLinkNew from "./MultiLinks/New";
import AttendanceList from "./Attendance/List";
import AttendanceEdit from "./Attendance/Edit";
import AttendanceNew from "./Attendance/New";

export default function AssociatePage() {
  return (
    <Switch>
      <Route path="/app/shorturl" component={ShorturlPage} />
      <Route path="/app/multilink/list" component={MultiLinkList} />
      <Route path="/app/multilink/edit" component={MultiLinkEdit} />
      <Route path="/app/multilink/new" component={MultiLinkNew} />
      <Route path="/app/attendance/list" component={AttendanceList} />
      <Route path="/app/attendance/edit" component={AttendanceEdit} />
      <Route path="/app/attendance/new" component={AttendanceNew} />
    </Switch>
  );
}
