import React from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as qs from "query-string";
import { injectIntl } from "react-intl";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import { setPassword } from "../../api/auth";
import { findEmailByToken } from "../../api/auth";

function Registration(props) {
  const [role, setRole] = React.useState("Admin");
  const [token, setToken] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [invalid, setInvalid] = React.useState(true);

  React.useEffect(() => {
    async function fetchEmail(key) {
      const mail = await findEmailByToken(key);
      setEmail(mail);
    }
    const { location } = props;
    if (location && location.search) {
      const parsed = qs.parse(location.search);
      if (parsed.token) {
        setInvalid(false);
        fetchEmail(parsed.token);
        setToken(parsed.token);
      }
      setRole(parsed.role);
    }
  }, [props, token]);

  const { intl } = props;

  return (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3>
            Pendaftaran{" "}
            {role.charAt(0) +
              role
                .slice(1)
                .toLowerCase()
                .replace("_", " ")}
          </h3>
        </div>
        {invalid ? (
          <>
            <div role="alert" className="alert alert-danger">
              <div className="alert-text">Invalid token</div>
            </div>
            <Link to="/auth">
              <button
                type="button"
                className="btn btn-secondary btn-elevate kt-login__btn-secondary"
              >
                Back
              </button>
            </Link>
          </>
        ) : (
          <Formik
            initialValues={{
              name: "",
              password: "",
              acceptTerms: false,
              confirmPassword: "",
            }}
            validate={(values) => {
              const errors = {};

              if (!values.name) {
                errors.name = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              }

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              }

              if (!values.confirmPassword) {
                errors.confirmPassword = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              } else if (values.password !== values.confirmPassword) {
                errors.confirmPassword =
                  "Password and Confirm Password didn't match.";
              }

              if (!values.acceptTerms) {
                errors.acceptTerms = "Accept Terms";
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              setPassword(values.name, values.password, token)
                .then((result) => {
                  const { accessToken } = result;
                  props.login(accessToken);
                })
                .catch((err) => {
                  console.log(err);
                  setSubmitting(false);
                  setStatus("Akaun gagal diaktifkan");
                });
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="form-group mb-0">
                  <TextField
                    margin="normal"
                    label="Email"
                    className="kt-width-full"
                    name="Email"
                    onBlur={handleBlur}
                    value={email}
                    disabled
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    margin="normal"
                    label="Name"
                    className="kt-width-full"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    helperText={touched.name && errors.name}
                    error={Boolean(touched.name && errors.name)}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Password"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Confirm Password"
                    className="kt-width-full"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                  />
                </div>

                <div className="form-group mb-0">
                  <FormControlLabel
                    label={
                      <>
                        I agree the{" "}
                        <Link
                          to="/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms & Conditions
                        </Link>
                      </>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        name="acceptTerms"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        checked={values.acceptTerms}
                      />
                    }
                  />
                </div>

                <div className="kt-login__actions">
                  <Link to="/auth">
                    <button
                      type="button"
                      className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                    >
                      Back
                    </button>
                  </Link>

                  <button
                    disabled={isSubmitting || !values.acceptTerms}
                    className="btn btn-primary btn-elevate kt-login__btn-primary"
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
