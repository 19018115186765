import React from "react";
import { Formik } from "formik";
import {
  TextField,
  Snackbar,
  Button,
  makeStyles,
  TableContainer,
  Paper,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Image } from "cloudinary-react";
import { useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as qs from "query-string";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../../partials/content/Portlet";
import { getUrlsById, updateUrl, removeUrl } from "../../../../api/multilink";
import { getUsers } from "../../../../api/user";
import { wallpapers } from "../../../../utils/constant";
import { toAbsoluteUrl } from "../../../../../_metronic";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

var currRow = null,
  dragElem = null,
  mouseDownX = 0,
  mouseDownY = 0,
  mouseX = 0,
  mouseY = 0,
  mouseDrag = false;

export default function MultiLinkEdit(props) {
  const { id } = useSelector(({ auth }) => auth.user);
  const classes = useStyles();
  const [details, setDetails] = React.useState({
    desc: "",
    title: "",
    url: "",
    background: "",
    shared: false,
  });
  const [uploadedImage, setUploadedImage] = React.useState(undefined);
  const [success, setSuccess] = React.useState(false);
  const [failure, setFailure] = React.useState(false);
  const [links, setLinks] = React.useState([]);
  const [error, setError] = React.useState("Multilink update Failed!");
  const [users, setUsers] = React.useState([]);
  const [collabs, setCollabs] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const authToken = useSelector(({ auth }) => auth.authToken);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = await getUsers(authToken);
        if (data) {
          const filtered = await data.filter((user) => user.id !== id);
          setUsers(filtered);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [authToken, id]);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const parsed = qs.parse(props.location.search);
        const data = await getUrlsById(parsed.id);
        if (data) {
          setDetails({
            id: data._id,
            desc: data.desc,
            title: data.title,
            url: data.url,
            background: data.background,
          });
          setCollabs(data.collaborators);
          setUploadedImage(data.icon);
          setLinks(data.links);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [props.location.search]);

  React.useEffect(() => {
    bindMouse();
    return () => {
      unBindMouse();
    };
  }, []);

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };
  const handleCloseFailure = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFailure(false);
  };

  const uploadWidget = () => {
    window.cloudinary.openUploadWidget(
      {
        cloud_name: "denr13kdi",
        upload_preset: "artex0ek",
        showUploadMoreButton: false,
        context: { userId: id },
      },
      function(error, result) {
        console.log(error);
        if (!error && result && result.event === "success") {
          console.log("Done! Here is the image info: ", result.info);
          setUploadedImage(result.info.url);
        }
      }
    );
  };

  function bindMouse() {
    document.addEventListener("mousedown", (event) => {
      if (event.button !== 0) return true;

      let target = getTargetRow(event.target);
      if (target) {
        currRow = target;
        addDraggableRow(target);
        currRow.classList.add("is-dragging");

        let coords = getMouseCoords(event);
        mouseDownX = coords.x;
        mouseDownY = coords.y;

        mouseDrag = true;
      }
    });

    document.addEventListener("mousemove", (event) => {
      if (!mouseDrag) return;

      let coords = getMouseCoords(event);
      mouseX = coords.x - mouseDownX;
      mouseY = coords.y - mouseDownY;

      moveRow(mouseX, mouseY);
    });

    document.addEventListener("mouseup", (event) => {
      if (!mouseDrag) return;

      currRow.classList.remove("is-dragging");
      document.getElementById("table").removeChild(dragElem);

      dragElem = null;
      mouseDrag = false;
    });
  }

  function unBindMouse() {
    document.removeEventListener("mousedown", (event) => {
      if (event.button !== 0) return true;

      let target = getTargetRow(event.target);
      if (target) {
        currRow = target;
        addDraggableRow(target);
        currRow.classList.add("is-dragging");

        let coords = getMouseCoords(event);
        mouseDownX = coords.x;
        mouseDownY = coords.y;

        mouseDrag = true;
      }
    });

    document.removeEventListener("mousemove", (event) => {
      if (!mouseDrag) return;

      let coords = getMouseCoords(event);
      mouseX = coords.x - mouseDownX;
      mouseY = coords.y - mouseDownY;

      moveRow(mouseX, mouseY);
    });

    document.removeEventListener("mouseup", (event) => {
      if (!mouseDrag) return;

      currRow.classList.remove("is-dragging");
      document.getElementById("table").removeChild(dragElem);

      dragElem = null;
      mouseDrag = false;
    });
  }

  function swapRow(row, index) {
    let currIndex = Array.from(
        document.getElementById("table").querySelector("tbody").children
      ).indexOf(currRow),
      row1 = currIndex > index ? currRow : row,
      row2 = currIndex > index ? row : currRow;

    document
      .getElementById("table")
      .querySelector("tbody")
      .insertBefore(row1, row2);
  }

  function moveRow(x, y) {
    dragElem.style.transform = "translate3d(" + x + "px, " + y + "px, 0)";

    let dPos = dragElem.getBoundingClientRect(),
      currStartY = dPos.y,
      currEndY = currStartY + dPos.height,
      rows = getRows();

    for (var i = 0; i < rows.length; i++) {
      let rowElem = rows[i],
        rowSize = rowElem.getBoundingClientRect(),
        rowStartY = rowSize.y,
        rowEndY = rowStartY + rowSize.height;

      if (
        currRow !== rowElem &&
        isIntersecting(currStartY, currEndY, rowStartY, rowEndY)
      ) {
        if (Math.abs(currStartY - rowStartY) < rowSize.height / 2)
          swapRow(rowElem, i);
      }
    }
  }

  function addDraggableRow(target) {
    dragElem = target.cloneNode(true);
    dragElem.classList.add("draggable-table__drag");
    dragElem.style.height = getStyle(target, "height");
    dragElem.style.background = getStyle(target, "backgroundColor");
    for (var i = 0; i < target.children.length; i++) {
      let oldTD = target.children[i],
        newTD = dragElem.children[i];
      newTD.style.width = getStyle(oldTD, "width");
      newTD.style.height = getStyle(oldTD, "height");
      newTD.style.padding = getStyle(oldTD, "padding");
      newTD.style.margin = getStyle(oldTD, "margin");
    }

    document.getElementById("table").appendChild(dragElem);

    let tPos = target.getBoundingClientRect(),
      dPos = dragElem.getBoundingClientRect();
    dragElem.style.bottom = dPos.y - tPos.y - tPos.height + "px";
    dragElem.style.left = "-1px";

    document.dispatchEvent(
      new MouseEvent("mousemove", {
        view: window,
        cancelable: true,
        bubbles: true,
      })
    );
  }

  function getRows() {
    return document.getElementById("table").querySelectorAll("tbody tr");
  }

  function getTargetRow(target) {
    let elemName = target.tagName.toLowerCase();

    if (elemName === "tr") return target;
    if (elemName === "td") return target.closest("tr");
  }

  function getMouseCoords(event) {
    return {
      x: event.clientX,
      y: event.clientY,
    };
  }

  function getStyle(target, styleName) {
    let compStyle = getComputedStyle(target),
      style = compStyle[styleName];

    return style ? style : null;
  }

  function isIntersecting(min0, max0, min1, max1) {
    return (
      Math.max(min0, max0) >= Math.min(min1, max1) &&
      Math.min(min0, max0) <= Math.max(min1, max1)
    );
  }

  function sortLink() {
    try {
      const sortedLinks = [];
      const rows = getRows();
      rows.forEach((r) => {
        const title = r.children[0].innerHTML.replace("&amp;", "&");
        const link = links.find((l) => l.title === title);
        sortedLinks.push(link);
      });
      return sortedLinks;
    } catch {
      return links;
    }
  }

  return (
    <>
      <Portlet>
        <PortletBody>
          <Formik
            enableReinitialize={true}
            onReset={(values) => {}}
            initialValues={{ ...details }}
            style={{ maxWidth: 400 }}
            onSubmit={(values, { setSubmitting }) => {
              const sortedLinks = sortLink();
              updateUrl(details.id, {
                ...values,
                icon: uploadedImage,
                links: sortedLinks,
                collaborators: collabs,
              })
                .then(() => {
                  setSuccess(true);
                  setSubmitting(false);
                  setTimeout(() => {
                    window.location.href = "/app/multilink/list";
                  }, 1000);
                })
                .catch((err) => {
                  setError(err.message || "Failed");
                  setFailure(true);
                  setSubmitting(false);
                });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <PortletHeader title="Ubah Suai Laman">
                    <div>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        className="btn btn-lg btn-elevate btn-success m-2"
                      >
                        Kemaskini
                      </Button>
                      <Button
                        type="button"
                        className="btn btn-lg btn-elevate btn-label-brand m-2"
                        onClick={() => {
                          window.location.href = "/app/multilink/list";
                        }}
                      >
                        Kembali
                      </Button>
                    </div>
                  </PortletHeader>
                  <div className="row">
                    <div className="col-12">
                      <p className="mt-3">Gambar Ikon (tidak wajib)</p>
                      {!uploadedImage ? (
                        <Button
                          onClick={() => uploadWidget()}
                          className="btn btn-lg btn-elevate btn-label m-3"
                        >
                          Tambah Gambar
                        </Button>
                      ) : (
                        <>
                          <Image
                            className="mb-3"
                            cloudName="denr13kdi"
                            publicId={uploadedImage}
                            width={150}
                          />
                          <Button
                            onClick={() => setUploadedImage("")}
                            className="btn btn-lg btn-elevate btn-danger m-3"
                          >
                            Padam Gambar
                          </Button>
                        </>
                      )}
                      <p className="mt-3">Maklumat Asas</p>
                    </div>
                    <div className="col-md-8 col-sm-12">
                      <TextField
                        label="Tajuk Laman"
                        margin="normal"
                        type="text"
                        name="title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.title}
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <TextField
                        label="Nama Pautan"
                        margin="normal"
                        type="text"
                        name="url"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.url}
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </div>
                    <div className="col-md-8 col-sm-12">
                      <TextField
                        label="Huraian"
                        margin="normal"
                        rows={10}
                        type="text"
                        name="desc"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.desc}
                        variant="outlined"
                        fullWidth
                        multiline
                        required
                      />
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <p className="mt-3"> Gambar latar belakang</p>
                      <Select
                        style={{ padding: 15 }}
                        className="dropdown-menu"
                        name="background"
                        value={values.background}
                        onChange={handleChange}
                      >
                        {wallpapers.map((bg) => (
                          <MenuItem
                            className="dropdown-item"
                            value={bg.replace("-", "")}
                          >
                            <img
                              width={215}
                              src={toAbsoluteUrl(
                                `/media/bg/multilink/${bg}.png`
                              )}
                              alt={bg.replace("-", "")}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="col-12">
                      <p className="mt-3">Rakan kerja</p>
                      <Autocomplete
                        multiple
                        options={users.sort((a, b) =>
                          b.branch.localeCompare(a.branch)
                        )}
                        getOptionLabel={(option) =>
                          `${option.name} (${option.branch})`
                        }
                        onChange={(_e, values) => setCollabs(values)}
                        value={collabs}
                        onBlur={handleBlur}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="collaborators"
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                  </div>
                </form>
              </>
            )}
          </Formik>
          <Formik
            initialValues={{ title: "", link: "" }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              const { title, link } = values;
              links.push({
                title: title,
                type: 0,
                value: link,
                isActive: true,
              });
              setLinks(links);
              setSubmitting(false);
              resetForm();
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <form className={classes.root} onSubmit={handleSubmit}>
                <p className="mt-5">Senarai pautan untuk Laman</p>
                <div className="row">
                  {links.length > 0 && (
                    <div className="col-12">
                      <TableContainer
                        style={{
                          height: links.length === 1 ? 125 : links.length * 100,
                        }}
                        component={Paper}
                      >
                        <table id="table" class="draggable-table">
                          <thead>
                            <th>Tajuk</th>
                            <th>Pautan</th>
                            <th>Aktif</th>
                            <th>Menu</th>
                          </thead>
                          <tbody id="table-body">
                            {links.map((link, index) => (
                              <tr key={index}>
                                <td>{link.title}</td>
                                <td style={{ maxWidth: 650 }}>{link.value}</td>
                                <td>
                                  <Switch
                                    checked={link.isActive}
                                    onClick={() => {
                                      const filtered = links.map((item) => {
                                        if (item.title === link.title) {
                                          item.isActive = !link.isActive;
                                        }
                                        return item;
                                      });
                                      setLinks(filtered);
                                    }}
                                  />
                                </td>
                                <td>
                                  <DeleteIcon
                                    color="action"
                                    onClick={() => {
                                      const filtered = links.filter(
                                        (item) => item.title !== link.title
                                      );
                                      setLinks(filtered);
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </TableContainer>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-5">
                    <TextField
                      label="Tajuk"
                      margin="normal"
                      type="text"
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </div>
                  <div className="col-5">
                    <TextField
                      label="Pautan"
                      margin="normal"
                      type="text"
                      name="link"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.link}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </div>
                  <div className="col-2">
                    <Button
                      type="submit"
                      variant="contained"
                      className="btn btn-lg btn-label-brand mt-5"
                    >
                      Tambah
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={success}
            autoHideDuration={6000}
            onClose={handleCloseSuccess}
          >
            <MuiAlert onClose={handleCloseSuccess} severity="success">
              Multilink successfully updated
            </MuiAlert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={failure}
            autoHideDuration={6000}
            onClose={handleCloseFailure}
          >
            <MuiAlert onClose={handleCloseFailure} severity="error">
              {error}
            </MuiAlert>
          </Snackbar>
        </PortletBody>
      </Portlet>
      <div>
        <Button
          type="button"
          className="btn btn-lg btn-elevate btn-danger m-3"
          onClick={handleClickOpen}
        >
          Buang
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Anda yakin untuk membuang?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Selepas dibuang, pautan tidak boleh dikembalikan lagi. Anda yakin
            dengan tindakan anda ini?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Tidak</Button>
          <Button
            onClick={() => {
              removeUrl(details.id);
              setTimeout(
                () => (window.location.href = "/app/multilink/list"),
                1000
              );
            }}
            autoFocus
          >
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
