import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard";
import DocsPage from "./docs/DocsPage";
import MemberPage from "./member/MemberPage";
import EventPage from "./event/EventPage";
import AssociatePage from "./associate/AssociatePage";
import ManagePage from "./manage/index";
import AppPage from "./app/index";
import SettingPage from "./setting/index";
import { LayoutSplashScreen } from "../../../_metronic";
import GoogleMaterialPage from "./google-material/GoogleMaterialPage";

export default function HomePage() {
  // useEffect(() => {
  //   console.log('Home page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/associate" component={AssociatePage} />
        <Route path="/member" component={MemberPage} />
        <Route path="/event" component={EventPage} />
        <Route path="/manage" component={ManagePage} />
        <Route path="/setting" component={SettingPage} />
        <Route path="/app" component={AppPage} />
        <Route path="/resctricted" component={DocsPage} />
        <Route path="/docs" component={DocsPage} />
        <Redirect to="/error/error1" />
      </Switch>
    </Suspense>
  );
}
