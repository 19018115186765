import React from "react";
import { Formik } from "formik";
import {
  TextField,
  Snackbar,
  CircularProgress,
  Button,
} from "@material-ui/core";
import * as qs from "query-string";
import { QRCodeSVG } from "qrcode.react";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../../partials/content/Portlet";
import { getActivityByIdAdmin, updateActivity } from "../../../../api/activity";
import moment from "moment";

export default function EditAttendancePage(props) {
  const [refresh, setRefresh] = React.useState(false);
  const [details, setDetails] = React.useState({
    id: "",
    userId: "",
    name: "",
    location: "",
    startDate: "",
    endDate: "",
    eventId: "",
    activityStatus: 0,
    attendances: [],
  });
  const [isLoading, setIsLoading] = React.useState(true);
  const [success, setSuccess] = React.useState(false);
  const [failure, setFailure] = React.useState(false);
  const [error, setError] = React.useState("Registartion Failed!");
  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };
  const handleCloseFailure = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFailure(false);
  };

  React.useEffect(() => {
    async function fetchData() {
      try {
        const parsed = qs.parse(props.location.search);
        const data = await getActivityByIdAdmin(parsed.id);
        if (data) {
          setDetails(data);
          setRefresh(false);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [props.location.search, refresh]);

  return (
    <Portlet>
      <PortletHeader title="Maklumat Aktiviti" />
      <PortletBody fluid={true}>
        {isLoading ? (
          <>Information is Loading...</>
        ) : (
          <Formik
            enableReinitialize={true}
            style={{ maxWidth: 400 }}
            initialValues={{
              ...details,
              startDate: moment
                .utc(details.startDate)
                .format("YYYY-MM-DDTkk:mm"),
              endDate: moment.utc(details.endDate).format("YYYY-MM-DDTkk:mm"),
            }}
            onSubmit={(values, { setSubmitting }) => {
              updateActivity(details.id, JSON.stringify(values, null, 2))
                .then(() => {
                  setSuccess(true);
                  setSubmitting(false);
                })
                .catch((err) => {
                  setError(err.message || "Failed");
                  setFailure(true);
                  setSubmitting(false);
                });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row p-3">
                  <div className="col-md-12 col-sm-12">
                    <TextField
                      label="Nama"
                      margin="normal"
                      type="text"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <TextField
                      label="Waktu Bermula"
                      margin="normal"
                      fullWidth
                      type="datetime-local"
                      name="startDate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.startDate}
                      variant="outlined"
                    />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <TextField
                      label="Waktu Tamat"
                      margin="normal"
                      fullWidth
                      type="datetime-local"
                      name="endDate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.endDate}
                      variant="outlined"
                    />
                  </div>
                  <div className="col-md-12 col-sm-12 mb-2">
                    <TextField
                      label="Lokasi"
                      margin="normal"
                      name="location"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.location}
                      variant="outlined"
                      fullWidth
                    />
                  </div>

                  {values.attendances && (
                    <div className="row">
                      <div className="col-12 mt-5">
                        <p>
                          Sukarelawan yang hadir {values.attendances.length}{" "}
                          orang
                          {/* <i className="flaticon-folder-2 ml-2" /> */}
                        </p>
                      </div>
                      <div className="col-md-9 col-sm-12 mt-5">
                        {values.attendances.map((a, index) => (
                          <tr>
                            <td>{index + 1}.</td>
                            <td className="p-3">{a.attendee.name}</td>
                            <td>{`(${moment
                              .utc(a.startTime)
                              .format("DD MMMM yy (h:mm a)")} - ${moment
                              .utc(a.endTime)
                              .format("DD MMMM yy (h:mm a)")})`}</td>
                          </tr>
                        ))}
                      </div>
                      <div className="col-md-3 col-sm-12 text-center">
                        <a
                          href={`https://sigmagema.com/attend?id=${values.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p>Click to Open</p>
                        </a>
                        <QRCodeSVG
                          value={`sigmagema.com/attend?id=${values.id}`}
                        />
                      </div>
                      <div className="col-md-12 col-sm-12 text-center mb-5 mt-3">
                        {!isSubmitting ? (
                          <Button
                            type="submit"
                            variant="contained"
                            className="btn btn-lg btn-elevate btn-label-brand"
                          >
                            Kemaskini
                          </Button>
                        ) : (
                          <CircularProgress />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </form>
            )}
          </Formik>
        )}

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={success}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
        >
          <MuiAlert onClose={handleCloseSuccess} severity="success">
            Information successfully added!
          </MuiAlert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={failure}
          autoHideDuration={6000}
          onClose={handleCloseFailure}
        >
          <MuiAlert onClose={handleCloseFailure} severity="error">
            {error}
          </MuiAlert>
        </Snackbar>
      </PortletBody>
    </Portlet>
  );
}
