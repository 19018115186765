export function truncateString(str, num) {
  // Check if the string length is greater than the specified number
  if (str.length > num) {
    // Return the truncated string with an ellipsis
    return str.slice(0, num) + "...";
  } else {
    // Return the string as is if it's shorter than the specified number
    return str;
  }
}
