import React from "react";
import { Link } from "react-router-dom";
import { Grid, makeStyles, Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import * as qs from "query-string";
import {
  getEventWithEnrollmentsById,
  getEventSheetsById,
} from "../../../api/event";
import { truncateString } from "../../../utils/text";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function ViewEventPage(props) {
  const classes = useStyles();
  const [event, setEvent] = React.useState(null);
  const [fields, setFields] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const parsed = qs.parse(props.location.search);
        const data = await getEventWithEnrollmentsById(parsed.id);
        if (data) setEvent(data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [props.location.search]);

  const columns = [
    {
      field: "id",
      title: "No",
      render: (rowData) => <p>{event.enrollments.indexOf(rowData) + 1}</p>,
    },
    { field: "userName", title: "Nama", width: 500 },
  ];

  // map event so that additionalInfo can be displayed into table. append to columns and get the title from customFields
  if (event && event.customFields) {
    Object.keys(event.customFields).forEach((key) => {
      fields.push({
        key,
        question: event.customFields[key].label,
      });
      columns.push({
        title: truncateString(event.customFields[key].label, 20),
        render: (rowData) => {
          const additionalInfo = rowData.additionalInfo;
          const value = additionalInfo[event.customFields[key].label];
          if (typeof value !== "object") {
            return <p>{value && value.toString()}</p>;
          } else {
            return (
              <p>
                {value &&
                  Object.keys(value).filter((key) => Boolean(value[key]))}
              </p>
            );
          }
        },
      });
    });
  }

  return (
    <Portlet>
      <PortletBody fluid={true}>
        <Grid className="p-3" container spacing={3}>
          <Grid item xs={12}>
            <div
              className="kt-section__sub"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <span>Senarai di bawah adalah peserta yang telah mendaftar</span>
              <Link
                className="btn btn-secondary btn-sm"
                to={`/event/list?status=${event && event.status}`}
                style={{ float: "right" }}
              >
                Kembali
              </Link>
            </div>
          </Grid>
          <Grid item xs={12}>
            {event && event.enrollments.length > 0 ? (
              <Paper className={classes.root}>
                <MaterialTable
                  title={`Senarai Peserta untuk ${event.name}`}
                  columns={columns}
                  data={event.enrollments}
                  options={{
                    exportButton: false,
                  }}
                  actions={[
                    {
                      icon: "save_alt",
                      tooltip: "Export Google Sheet",
                      isFreeAction: true,
                      onClick: async () => {
                        const parsed = qs.parse(props.location.search);
                        const data = await getEventSheetsById(parsed.id);
                        if (data.spreadsheetId) {
                          console.log(data.spreadsheetId);
                          window.open(
                            `https://docs.google.com/spreadsheets/d/${data.spreadsheetId}/edit?gid=0#gid=0`,
                            "_blank"
                          );
                        } else {
                          alert("Something When Wrong");
                        }
                      },
                    },
                  ]}
                />
              </Paper>
            ) : (
              <div className="mt-4 text-center">
                <h6>No Registered participants yet</h6>
              </div>
            )}
            {/* {fields && fields.length > 0 && (
              <div className="mt-5 ml-5">
                {fields.map((field) => {
                  return (
                    <p>
                      {field.key} = {field.question}
                    </p>
                  );
                })}
              </div>
            )} */}
          </Grid>
        </Grid>
      </PortletBody>
    </Portlet>
  );
}
