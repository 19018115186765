import axios from "axios";

const token = process.env.REACT_APP_MAIN_API_TOKEN;

const reportAxios = axios.create({
  baseURL: process.env.REACT_APP_MAIN_API_URL,
  headers: {
    "X-Request-Agent": "gema-dashboard",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export async function getReportData(branch) {
  try {
    const response = await axios.all([
      reportAxios.get(`/report/chart/pie/branch`),
      reportAxios.get(`/report/chart/pie/membertype?branch=${branch}`),
      reportAxios.get(`/report/chart/pie/memberstatus?branch=${branch}`),
      reportAxios.get(`/report/chart/pie/race?branch=${branch}`),
      reportAxios.get(`/report/chart/pie/religion?branch=${branch}`),
      reportAxios.get(`/report/chart/pie/gender?branch=${branch}`),
    ]);
    const data = response.map((res) => {
      return res.data;
    });
    return {
      branch: data[0],
      memberType: data[1],
      memberStatus: data[2],
      race: data[3],
      agama: data[4],
      gender: data[5],
    };
  } catch (error) {
    console.log(error);
  }
}
