import React from "react";
import { Route, Switch } from "react-router-dom";
import FeePage from "./Fee";

export default function ManagePage() {
    return (
      <Switch>
        <Route 
          path="/setting/fee" 
          component={FeePage}
        />
      </Switch>
    );
  }