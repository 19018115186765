import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";

export default function QuickStatsChart(props) {
  const { datasets = [], title, desc } = props;
  const canvasRef = useRef();

  useEffect(() => {
    const config = {
      type: "pie",
      data: datasets,
      options: {
        title: {
          display: false,
        },
        tooltips: {
          enabled: true,
          caretPadding: 10,
        },
        legend: {
          display: true,
          position: "bottom",
          labels: {
            usePointStyle: true,
            margin: 10,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: "ErrorsPage.js",
        },
      },
    };

    const chart = new Chart(canvasRef.current, config);

    return () => {
      chart.destroy();
    };
  }, [datasets]);

  return (
    <div className="kt-widget26">
      <div className="kt-widget26__content">
        <span className="kt-widget26__number">{title}</span>
        <span className="kt-widget26__desc">{desc}</span>
      </div>
      <div className="kt-widget26__chart">
        <canvas ref={canvasRef} />
      </div>
    </div>
  );
}
