import React from "react";
import { Grid, makeStyles, Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { getAdmin } from "../../../../api/user";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function AdminsPage(props) {
  const classes = useStyles();
  const [adminRows, setAdminRows] = React.useState([]);

const authToken = useSelector(({auth}) => auth.authToken);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAdmin(authToken);
        console.log(data)
        if (data) {
          setAdminRows(data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [authToken]);

  const columns = [
    {
      field: "id",
      title: "No",
      width: 50,
      render: (rowData) => <p>{adminRows.indexOf(rowData) + 1}</p>,
    },
    { field: "name", title: "Nama", width: 300 },
    {
      field: "email",
      title: "E-mel",
    },
    { field: "branch", title: "Cawangan Gema" },
  ];

  return (
    <>
      <Portlet>
        <PortletBody fluid={true}>
          <Grid className="p-3" container spacing={3}>
            <Grid item xs={12}>
              <span className="kt-section__sub">
                Senarai admin yang aktif untuk menguruskan sistem.
              </span>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.root}>
                <MaterialTable
                  columns={columns}
                  data={adminRows}
                  title={`Senarai Admin`}
                  options={{
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportCsv: (columns, data) => {
                      alert(
                        "You should develop a code to export " +
                          data.length +
                          " rows"
                      );
                    },
                  }}
                />
              </Paper>
            </Grid>
          </Grid>
        </PortletBody>
      </Portlet>
    </>
  );
}
