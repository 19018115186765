import axios from "axios";

const token = process.env.REACT_APP_MAIN_API_TOKEN;

const memberAxios = axios.create({
  baseURL: process.env.REACT_APP_MAIN_API_URL,
  headers: {
    "X-Request-Agent": "gema-dashboard",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export async function getAllMembers() {
  try {
    const { data } = await memberAxios.get("member");
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getMembersByStatus(qs, branch) {
  try {
    const { data } = await memberAxios.get(
      `/member/filtered?status=${qs}&branch=${branch}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getMembersByBranch(branch) {
  try {
    const { data } = await memberAxios.get(`/member/branch?branch=${branch}`);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getMembersById(qs) {
  try {
    const { data } = await memberAxios.get(`/member/details?id=${qs}`);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function updateMember(id, memberDto) {
  try {
    const response = await memberAxios.put(`/member/${id}`, memberDto);
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (error) {
    throw error.response.data;
  }
}

export async function approve(id, approver) {
  try {
    const response = await memberAxios.put(`/member/approved/${id}`, {
      approver,
    });
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (error) {
    throw error.response.data;
  }
}

export async function reject(id, remarks, approver) {
  try {
    const response = await memberAxios.put(`/member/rejected/${id}`, {
      approver,
      remarks,
    });
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (error) {
    throw error.response.data;
  }
}

export async function createNewMember(memberDto) {
  try {
    const response = await memberAxios.post("/member", memberDto);
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getMembersLogs() {
  try {
    const { data } = await memberAxios.get("member/logs");
    return data.filter((lg) => lg.title.includes("by staff"));
  } catch (error) {
    console.log(error);
  }
}

export async function getExpiredMembers() {
  try {
    const { data } = await memberAxios.get("member/expired/update");
    return data;
  } catch (error) {
    console.log(error);
  }
}
