import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import {
  Checkbox,
  TextField,
  Snackbar,
  CircularProgress,
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Editor } from "@tinymce/tinymce-react";
import moment from "moment";
import "moment-timezone";
import * as qs from "query-string";
import Delete from "@material-ui/icons/Delete";
import Done from "@material-ui/icons/Done";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../partials/content/Portlet";
import { getEventById, updateEvent } from "../../../api/event";
import {
  eventEligibility,
  eventStatus,
  eventFieldType,
} from "../../../utils/constant";

export default function EditEventPage(props) {
  const editorRef = React.useRef(null);
  const editorNoteRef = React.useRef(null);
  const [refresh, setRefresh] = useState(false);
  const [details, setDetails] = useState({
    id: "",
    name: "",
    description: "",
    startDate: "",
    eventStatus: 0,
    endDate: "",
    venue: "",
    remarks: "",
    fee: 0,
    limit: 1000,
    memberPrice: 0,
    billingId: "",
    registeredMembers: [],
    attendedMembers: [],
    registeredAssociates: [],
    attendedAssociates: [],
    activeOnly: true,
    registrationNote: "",
    posterUrl: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [error, setError] = useState("Registartion Failed!");
  // dynamic form fields
  const [customFields, setCustomFields] = useState({});

  const addField = (label, type, options) => {
    if (customFields == null) {
      setCustomFields({
        field1: { label, type, options, disabled: false },
      });
    } else {
      const fieldName = `field${Object.keys(customFields).length + 1}`;
      setCustomFields({
        ...customFields,
        [fieldName]: { label, type, options, disabled: false },
      });
    }
  };

  // update status to 0
  const disableCustomField = (fieldName) => {
    return () => {
      setCustomFields({
        ...customFields,
        [fieldName]: { ...customFields[fieldName], disabled: true },
      });
    };
  };

  const enableCustomField = (fieldName) => {
    return () => {
      setCustomFields({
        ...customFields,
        [fieldName]: { ...customFields[fieldName], disabled: false },
      });
    };
  };

  const renderCustomField = (customField) => {
    const { label, options, type, disabled } = customField;
    if (type === "Text") {
      return (
        <div className="col-12">
          <TextField
            label={label}
            margin="normal"
            type="text"
            variant="outlined"
            disabled={disabled}
            fullWidth
          />
        </div>
      );
    } else if (type === "Dropdown") {
      return (
        <div className="col-12">
          <Autocomplete
            options={options.split(",")}
            disabled={disabled}
            getOptionLabel={(options) => options}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
      );
    } else if (type === "Checkbox") {
      return (
        <div className="col-12">
          <FormGroup>
            {options.split(",").map((val) => (
              <FormControlLabel
                disabled={disabled}
                key={val}
                control={<Checkbox />}
                label={val}
              />
            ))}
          </FormGroup>
        </div>
      );
    } else if (type === "Radio") {
      return (
        <div className="col-12">
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              {options.split(",").map((val) => (
                <FormControlLabel
                  disabled={disabled}
                  key={val}
                  value={val}
                  control={<Radio />}
                  label={val}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      );
    } else if (type === "Switch") {
      return (
        <div className="col-12">
          <FormGroup>
            <FormControlLabel
              disabled={disabled}
              control={<Switch defaultChecked />}
              label={label}
            />
          </FormGroup>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };
  const handleCloseFailure = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFailure(false);
  };

  React.useEffect(() => {
    async function fetchData() {
      try {
        const parsed = qs.parse(props.location.search);
        const data = await getEventById(parsed.id);
        if (data) {
          setDetails({
            ...data,
            startDate: moment(data.startDate)
              .tz("Asia/Kuala_Lumpur")
              .format("YYYY-MM-DDTHH:mm"),
            endDate: moment
              .utc(data.endDate)
              .tz("Asia/Kuala_Lumpur")
              .format("YYYY-MM-DDTHH:mm"),
          });
          setCustomFields(data.customFields);
          setRefresh(false);
          setIsLoading(false);
          editorRef.current.setContent(data.description);
          editorNoteRef.current.setContent(data.registrationNote);
        }
        console.log(details.startDate);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [props.location.search, refresh]);

  return (
    <Portlet>
      <PortletHeader title="Maklumat Peristiwa">
        <Link
          className="btn btn-secondary mt-4 mb-4"
          to={`/event/list?status=${details && details.status}`}
        >
          Kembali
        </Link>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={success}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
        >
          <MuiAlert onClose={handleCloseSuccess} severity="success">
            Information successfully added!
          </MuiAlert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={failure}
          autoHideDuration={6000}
          onClose={handleCloseFailure}
        >
          <MuiAlert onClose={handleCloseFailure} severity="error">
            {error}
          </MuiAlert>
        </Snackbar>
      </PortletHeader>
      <PortletBody fluid={true}>
        {isLoading ? (
          <>Information is Loading...</>
        ) : (
          <Formik
            enableReinitialize={true}
            onReset={(values) => {}}
            style={{ maxWidth: 400 }}
            initialValues={{
              ...details,
              customFieldType: "Text",
              customFieldQuestion: "",
              customFieldOptions: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              delete values.customFieldQuestion;
              delete values.customFieldOptions;
              delete values.customFieldType;
              const modfiedValues = {
                ...values,
                customFields,
              };
              updateEvent(details.id, modfiedValues)
                .then(() => {
                  setSuccess(true);
                  setSubmitting(false);
                })
                .catch((err) => {
                  setError(err.message || "Failed");
                  setFailure(true);
                  setSubmitting(false);
                });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row p-3">
                  <div className="col-md-12 col-sm-12">
                    <TextField
                      label="Nama"
                      margin="normal"
                      type="text"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <Editor
                      apiKey={process.env.REACT_APP_TINY_EDITOR_APIKEY}
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      value={values.description}
                      onEditorChange={(val) => {
                        setFieldValue("description", val);
                      }}
                      init={{
                        height: 200,
                        menubar: false,
                        plugins: [
                          "advlist",
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "code",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "code",
                          "help",
                          "wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <TextField
                      label="Waktu Bermula"
                      margin="normal"
                      fullWidth
                      type="datetime-local"
                      name="startDate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.startDate}
                      variant="outlined"
                    />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <TextField
                      label="Waktu Tamat"
                      margin="normal"
                      fullWidth
                      type="datetime-local"
                      name="endDate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.endDate}
                      variant="outlined"
                    />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <TextField
                      label="Jumlah Peserta"
                      margin="normal"
                      name="limit"
                      type="number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.limit}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <TextField
                      label="Yuran Penyertaan (RM)"
                      margin="normal"
                      name="fee"
                      type="number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.fee}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <TextField
                      label="Yuran Untuk Ahli (RM)"
                      margin="normal"
                      name="memberPrice"
                      type="number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.memberPrice}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <TextField
                      label="Billing Id"
                      margin="normal"
                      name="billingId"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.billingId}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <TextField
                      label="Tempat"
                      margin="normal"
                      name="venue"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.venue}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <Autocomplete
                      options={eventStatus}
                      getOptionLabel={(options) => options.title}
                      value={eventStatus[values.eventStatus || 0]}
                      onChange={(_e, val) =>
                        setFieldValue("eventStatus", val ? val.value : 0)
                      }
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Status"
                          margin="normal"
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <Autocomplete
                      options={eventEligibility}
                      getOptionLabel={(options) => options.title}
                      value={eventEligibility[values.eligibility || 0]}
                      onChange={(_e, val) =>
                        setFieldValue("eventEligibility", val ? val.value : 0)
                      }
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="eligibility"
                          margin="normal"
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <TextField
                      label="Pautan Poster"
                      margin="normal"
                      name="posterUrl"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.posterUrl}
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <p className="mt-2">
                      Maklumat untuk peserta selepas mendaftar
                    </p>
                    <Editor
                      apiKey={process.env.REACT_APP_TINY_EDITOR_APIKEY}
                      onInit={(evt, editor) => (editorNoteRef.current = editor)}
                      value={values.registrationNote}
                      onEditorChange={(val) => {
                        setFieldValue("registrationNote", val);
                      }}
                      init={{
                        height: 100,
                        menubar: false,
                        plugins: [
                          "advlist",
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "code",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "code",
                          "help",
                          "wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                  </div>
                  <p className="mt-4 mb-4">Custom Fields (Sekiranya perlu)</p>
                  {customFields &&
                    Object.keys(customFields).map((key, index) => (
                      <div className="row mb-4" key={key}>
                        <div className="col-12">
                          <h6>
                            {customFields[key].disabled ? (
                              <span style={{ textDecoration: "line-through" }}>
                                {index + 1}. {customFields[key].label}
                                <Done
                                  style={{ marginLeft: "7px" }}
                                  onClick={() => enableCustomField(key)}
                                />
                              </span>
                            ) : (
                              <span>
                                {index + 1}. {customFields[key].label}
                                <Delete
                                  style={{ marginLeft: "7px" }}
                                  onClick={() => disableCustomField(key)}
                                />
                              </span>
                            )}
                          </h6>
                        </div>
                        {renderCustomField(customFields[key])}
                      </div>
                    ))}
                  <div className="col-3">
                    <Autocomplete
                      options={eventFieldType}
                      getOptionLabel={(options) => options.title}
                      value={eventFieldType[values.customFieldType || 0]}
                      onChange={(_e, val) =>
                        setFieldValue(
                          "customFieldType",
                          val ? val.value : "text"
                        )
                      }
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Jenis"
                          margin="normal"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="col-5">
                    <TextField
                      label="Soalan"
                      margin="normal"
                      name="customFieldQuestion"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.customFieldQuestion}
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="col-4">
                    <TextField
                      label="Pilihan (Pisahkan dengan koma)"
                      margin="normal"
                      name="customFieldOptions"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.customFieldOptions}
                      variant="outlined"
                      fullWidth
                      disabled={
                        values.customFieldType === "Text" ||
                        values.customFieldType === "Switch"
                      }
                    />
                  </div>
                  <div className="mt-2 mb-10">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        addField(
                          values.customFieldQuestion,
                          values.customFieldType,
                          values.customFieldOptions
                        )
                      } // Set a default type or use the selected type from the dropdown
                    >
                      Tambah Field
                    </Button>
                  </div>
                  <div className="col-md-12 col-sm-12 text-center mb-5 mt-3">
                    {!isSubmitting ? (
                      <Button
                        type="submit"
                        variant="contained"
                        className="btn btn-lg btn-elevate btn-label-brand"
                      >
                        Kemaskini
                      </Button>
                    ) : (
                      <CircularProgress />
                    )}
                  </div>
                </div>
              </form>
            )}
          </Formik>
        )}
      </PortletBody>
    </Portlet>
  );
}
