import React from "react";
import { Route, Switch } from "react-router-dom";
import NewEventPage from "./NewEventPage";
import EditEventPage from "./EditEventPage";
import ListEventPage from "./ListEventPage";
import ViewEventPage from "./ViewEventPage";

export default function EventPage() {
  return (
    <Switch>
      <Route path="/event/new" component={NewEventPage} />
      <Route path="/event/edit" component={EditEventPage} />
      <Route path="/event/list" component={ListEventPage} />
      <Route path="/event/view" component={ViewEventPage} />
    </Switch>
  );
}
