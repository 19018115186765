import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { Portlet, PortletBody } from "../../partials/content/Portlet";
import { metronic } from "../../../_metronic";
import QuickStatsChart from "../../widgets/QuickStatsChart";
import {
  getAllMembersData,
  getActiveMembersData,
  getNewMembersData,
  getRevenueData,
} from "../../api/dashboard";

export default function Dashboard() {
  const [chart1, setChart1] = React.useState([]);
  const [chart2, setChart2] = React.useState([]);
  const [chart3, setChart3] = React.useState([]);
  const [chart4, setChart4] = React.useState([]);

  const { brandColor, dangerColor, successColor, primaryColor } = useSelector(
    (state) => ({
      brandColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.warning"
      ),
      dangerColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.danger"
      ),
      successColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.success"
      ),
      primaryColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.primary"
      ),
    })
  );

  const chartOptions = useMemo(
    () => ({
      chart1: {
        color: brandColor,
        border: 3,
      },

      chart2: {
        color: primaryColor,
        border: 3,
      },

      chart3: {
        color: successColor,
        border: 3,
      },

      chart4: {
        color: dangerColor,
        border: 3,
      },
    }),
    [brandColor, dangerColor, primaryColor, successColor]
  );

  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllMembersData();
        if (data) {
          setChart1(data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = await getNewMembersData();
        if (data) {
          setChart2(data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = await getRevenueData();
        if (data) {
          setChart4(data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = await getActiveMembersData();
        if (data) {
          setChart3(data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <div className="row">
        <div className="m-2 w-100">
          {(chart1.length === 0 ||
            chart2.length === 0 ||
            chart3.length === 0 ||
            chart4.length === 0) && (
            <Alert severity="warning">Please wait. Chart is loading!</Alert>
          )}
        </div>
        <div className="col-12">
          <div className="row row-full-height">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={chart1.length > 0 ? chart1[0] : 0}
                    desc="Jumlah Ahli yang berdaftar"
                    data={chart1}
                    color={chartOptions.chart1.color}
                    border={chartOptions.chart1.border}
                  />
                </PortletBody>
              </Portlet>

              <div className="kt-space-20" />

              <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={chart2.length > 0 ? chart2[0] : 0}
                    desc="Ahli Baru"
                    data={chart2}
                    color={chartOptions.chart2.color}
                    border={chartOptions.chart2.border}
                  />
                </PortletBody>
              </Portlet>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-6">
              <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={chart3.length > 0 ? chart3[0] : 0}
                    desc="Jumlah Ahli Aktif"
                    data={chart3}
                    color={chartOptions.chart3.color}
                    border={chartOptions.chart3.border}
                  />
                </PortletBody>
              </Portlet>

              <div className="kt-space-20" />

              <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={chart4.length > 0 ? `RM ${chart4[0]}` : 0}
                    desc="Pendapatan Yuran "
                    data={chart4}
                    color={chartOptions.chart4.color}
                    border={chartOptions.chart4.border}
                  />
                </PortletBody>
              </Portlet>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-12">
          <Portlet fluidHeight={true}>
            <PortletHeader
              title="Statistik Ahli"
              toolbar={
                <PortletHeaderToolbar>
                  <PortletHeaderDropdown />
                </PortletHeaderToolbar>
              }
            />

            <PortletBody>
              <OrderStatisticsChart data={dashboard} />
            </PortletBody>
          </Portlet>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <NewUsers />
        </div>
      </div> */}
    </>
  );
}
