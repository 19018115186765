import axios from "axios";

const token = process.env.REACT_APP_MAIN_API_TOKEN;

const authAxios = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_IDENTITY_SERVICE_URL
      : process.env.REACT_APP_MAIN_API_URL_LOCAL,
  headers: {
    "X-Request-Agent": "gema-dashboard",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export async function findEmailByToken(token) {
  try {
    const { data } = await authAxios.get(`/auth/email?token=${token}`);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function login(email, password) {
  try {
    const { data } = await authAxios.post(`/auth/login`, { email, password });
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function setPassword(name, password, token) {
  try {
    const { data } = await authAxios.post(`/auth/password`, {
      name,
      password,
      token,
    });
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function resetPassword(email) {
  try {
    const { data } = await authAxios.post(`/auth/password/reset`, { email });
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getUserByToken(authToken) {
  try {
    const { data } = await authAxios.get(`/auth/jwt`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getUserList() {
  try {
    const { data } = await authAxios.get(`/auth/list`);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function createUser(registerDto) {
  try {
    const { data } = await authAxios.post(`/auth/register`, registerDto);
    return data;
  } catch (error) {
    const { response } = error;
    if (response) {
      throw new Error(response.data.message);
    }
    throw new Error(error);
  }
}
