import React from "react";
import { Formik } from "formik";
import {
  TextField,
  Snackbar,
  CircularProgress,
  Button,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../../partials/content/Portlet";
import { createNewActivity } from "../../../../api/activity";
import moment from "moment";

export default function AttendanceNew() {
  const { id } = useSelector(({ auth }) => auth.user);
  const [success, setSuccess] = React.useState(false);
  const [failure, setFailure] = React.useState(false);
  const [error, setError] = React.useState("Registartion Failed!");

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };
  const handleCloseFailure = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFailure(false);
  };

  return (
    <Portlet>
      <PortletHeader title="Maklumat Aktiviti" />
      <PortletBody fluid={true}>
        <Formik
          initialValues={{
            startDate: moment(new Date()).format("YYYY-MM-DDTkk:mm"),
            endDate: moment(new Date()).format("YYYY-MM-DDTkk:mm"),
            userId: id,
          }}
          style={{ maxWidth: 400 }}
          onSubmit={(values, { setSubmitting }) => {
            createNewActivity(JSON.stringify(values, null, 2))
              .then(() => {
                setSuccess(true);
                setSubmitting(false);
              })
              .catch((err) => {
                setError(err.message || "Failed");
                setFailure(true);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row p-3">
                <div className="col-md-12 col-sm-12">
                  <TextField
                    label="Peristiwa (sekiranya ada)"
                    margin="normal"
                    type="text"
                    name="eventId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.eventId}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <div className="col-md-12 col-sm-12">
                  <TextField
                    label="Nama"
                    margin="normal"
                    type="text"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <TextField
                    label="Waktu Bermula"
                    margin="normal"
                    fullWidth
                    type="datetime-local"
                    name="startDate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.startDate}
                    variant="outlined"
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <TextField
                    label="Waktu Tamat"
                    margin="normal"
                    fullWidth
                    type="datetime-local"
                    name="endDate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.endDate}
                    variant="outlined"
                  />
                </div>
                <div className="col-md-12 col-sm-12 mb-2">
                  <TextField
                    label="Lokasi"
                    margin="normal"
                    name="location"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.location}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </div>
                <div className="col-md-12 col-sm-12 text-center">
                  {!isSubmitting ? (
                    <Button
                      type="submit"
                      variant="contained"
                      className="btn btn-lg btn-elevate btn-label-brand"
                    >
                      Hantar
                    </Button>
                  ) : (
                    <CircularProgress />
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={success}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
        >
          <MuiAlert onClose={handleCloseSuccess} severity="success">
            Aktiviti baru berjaya direkodkan!
          </MuiAlert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={failure}
          autoHideDuration={6000}
          onClose={handleCloseFailure}
        >
          <MuiAlert onClose={handleCloseFailure} severity="error">
            {error}
          </MuiAlert>
        </Snackbar>
      </PortletBody>
    </Portlet>
  );
}
