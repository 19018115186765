import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, makeStyles, Paper, Chip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import MaterialTable from 'material-table';
import { Portlet, PortletBody } from '../../../partials/content/Portlet';
import { getAllMembers, getMembersByBranch, getMembersByStatus } from '../../../api/member';
import * as qs from 'query-string';
import exportToExcel from '../../../utils/exportToExcel';

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  }
});

export default function MemberListPage(props) {
  const { userType, branch } = useSelector(({ auth }) => auth.user);
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [memberStatus, setMemberStatus] = React.useState(0);
  const br = userType === 0 ? undefined : branch;

  React.useEffect(() => {
    async function fetchData() {
      try {
        const parsed = qs.parse(props.location.search);
        setMemberStatus(parsed.status)
        const data = parsed.status && parsed.status !== 'undefined' ? await getMembersByStatus(parsed.status, br) : br ? await getMembersByBranch(br) : await getAllMembers()
        if (data) {
          setRows(data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [props.location.search, br]);

  const getMemberStatus = (status) => {
    switch(status){
      case "PENDING":
        return "Belum Bayar"
      case "PAID":
        return "Baru"
      case "ACTIVE":
        return "Aktif"
      case "ALUMNI":
        return "Alumni"
      case "REJECTED":
        return "Tidak diluluskan"
      case "EXPIRED":
        return "Tamat Tempoh"
      default:
        return "Tiada Maklumat"
    }
  }

  const columns = [
    {
      field: 'id',
      title: 'No',
      width: 50,
      render: rowData => <p>{rows.indexOf(rowData) + 1}</p>
    },
    { field: 'fullname', title: 'Nama', width: 300 },
    {
      field: 'membershipId',
      title: 'No Ahli'
    },
    { field: 'branch', title: 'Cawangan Gema' },
    {
      field: 'memberStatus',
      title: 'Status',
      render: rowData => <Chip label={getMemberStatus(rowData.memberStatus)} color="primary" />
    },
    { field: 'remarks', title: 'Catatan', hidden: memberStatus !== '4' },
    {
      field: 'id',
      title: '',
      render: rowData => (
        <Link to={`/member/edit?id=${rowData.id}`}>
          <EditIcon color="action" />
        </Link>
      )
    }
  ];

  return (
    <Portlet>
      <PortletBody fluid={true}>
        <Grid className="p-3" container spacing={3}>
          <Grid item xs={12}>
            <span className="kt-section__sub">
              Senarai di bawah menrangkumi ahli yang telah mendaftar melalui
              sistem sigma.
            </span>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.root}>
              <MaterialTable
                columns={columns}
                data={rows}
                title={`Bilangan ahli: ${rows.length}`}
                options={{
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportCsv: (columns, data) => {
                    const st = data.map((member) => {
                      return {
                        No_Ahli: member.membershipId,
                        Cawangan: member.branch,
                        Nama: member.fullname,
                        No_IC: member.nationalId,
                        Umur: member.age,
                        No_Telefon: member.phone,
                        Emel: member.email,
                        Alamat: member.address,
                        Bandar: member.city,
                        Jantina: member.gender,
                        Pekerjaan:  member.occupation,
                        Pendidikan: member.education,
                        Agama: member.religion,
                        Bangsa: member.race,
                        Tarik_Mula: member.startDate,
                        Tarikh_Tamat: member.endDate,
                      }
                    })
                    exportToExcel(st)
                  }
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </PortletBody>
    </Portlet>
  );
}
