import React from "react";
import { Formik } from "formik";
import {
  TextField,
  Snackbar,
  Button,
  makeStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  Switch,
  Select,
  MenuItem,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Image } from "cloudinary-react";
import DeleteIcon from "@material-ui/icons/Delete";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../../partials/content/Portlet";
import { createUrl, getUrlByPath } from "../../../../api/multilink";
import { getUsers } from "../../../../api/user";
import { toAbsoluteUrl } from "../../../../../_metronic";
import { wallpapers } from "../../../../utils/constant";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function NewEventPage() {
  const { id, name } = useSelector(({ auth }) => auth.user);
  const classes = useStyles();
  const [uploadedImage, setUploadedImage] = React.useState(undefined);
  const [path, setPath] = React.useState("");
  const [duplicate, setDuplicate] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [failure, setFailure] = React.useState(false);
  const [links, setLinks] = React.useState([]);
  const [error, setError] = React.useState("Multilink Creation Failed!");
  const [users, setUsers] = React.useState([]);
  const [collabs, setCollabs] = React.useState([]);

  const authToken = useSelector(({ auth }) => auth.authToken);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = await getUsers(authToken);
        if (data) {
          const filtered = await data.filter((user) => user.id !== id);
          setUsers(filtered);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [authToken, id]);

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };
  const handleCloseFailure = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFailure(false);
  };

  const uploadWidget = () => {
    window.cloudinary.openUploadWidget(
      {
        cloud_name: "denr13kdi",
        upload_preset: "artex0ek",
        showUploadMoreButton: false,
        context: { userId: id },
      },
      function(error, result) {
        console.log(error);
        console.log(result);
        if (!error && result.length > 0) {
          setUploadedImage(result[0].url);
        }
      }
    );
  };

  React.useEffect(() => {
    const fetchUrl = async () => {
      if (path) {
        const page = await getUrlByPath(path);
        setDuplicate(!!page);
      }
    };
    fetchUrl();
  }, [path]);

  return (
    <>
      <Portlet>
        <PortletBody>
          <Formik
            initialValues={{
              title: "",
              url: "",
              desc: "",
              background: "bg1",
              shared: [],
            }}
            style={{ maxWidth: 400 }}
            validate={async (values) => {
              const errors = {};
              if (!values.url) {
                errors.url = "Field is required";
              }
              if (values.url && values !== path) {
                setPath(values.url);
              }
              if (duplicate) {
                errors.url = "Name already exist";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              createUrl({
                ...values,
                userId: id,
                userName: name,
                icon: uploadedImage,
                links,
                collaborators: collabs,
              })
                .then(() => {
                  setSuccess(true);
                  setSubmitting(false);
                  setTimeout(() => {
                    window.location.href = "/app/multilink/list";
                  }, 1000);
                })
                .catch((err) => {
                  setError(err.message || "Failed");
                  setFailure(true);
                  setSubmitting(false);
                });
            }}
          >
            {({
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <PortletHeader title="Laman Baru">
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      className="btn btn-lg btn-elevate btn-success m-3"
                    >
                      Hantar
                    </Button>
                  </PortletHeader>
                  <div className="row">
                    <div className="col-12">
                      <p className="mt-3">Gambar Ikon (tidak wajib)</p>
                      {!uploadedImage ? (
                        <Button
                          onClick={() => uploadWidget()}
                          className="btn btn-lg btn-elevate btn-label m-3"
                        >
                          Tambah Gambar
                        </Button>
                      ) : (
                        <>
                          <Image
                            className="mb-3"
                            cloudName="denr13kdi"
                            publicId={uploadedImage}
                            width={150}
                          />
                          <Button
                            onClick={() => setUploadedImage("")}
                            className="btn btn-lg btn-elevate btn-danger m-3"
                          >
                            Padam Gambar
                          </Button>
                        </>
                      )}
                      <p className="mt-3">Maklumat Asas</p>
                    </div>
                    <div className="col-md-8 col-sm-12">
                      <TextField
                        label="Tajuk Laman"
                        margin="normal"
                        type="text"
                        name="title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.title}
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <TextField
                        label="Nama Pautan"
                        margin="normal"
                        type="text"
                        name="url"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.url}
                        variant="outlined"
                        fullWidth
                        required
                      />
                      {errors.url && (
                        <span className="text-danger">{errors.url}</span>
                      )}
                    </div>
                    <div className="col-md-8 col-sm-12">
                      <TextField
                        label="Huraian"
                        margin="normal"
                        rows={10}
                        type="text"
                        name="desc"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.desc}
                        variant="outlined"
                        fullWidth
                        multiline
                        required
                      />
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <p className="mt-3"> Gambar latar belakang</p>
                      <Select
                        style={{ padding: 15 }}
                        className="dropdown-menu"
                        name="background"
                        value={values.background}
                        onChange={handleChange}
                      >
                        {wallpapers.map((bg) => (
                          <MenuItem
                            className="dropdown-item"
                            value={bg.replace("-", "")}
                          >
                            <img
                              width={215}
                              src={toAbsoluteUrl(
                                `/media/bg/multilink/${bg}.png`
                              )}
                              alt={bg.replace("-", "")}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="col-12">
                      <p className="mt-3">Rakan kerja</p>
                      <Autocomplete
                        multiple
                        options={users.sort((a, b) =>
                          b.branch.localeCompare(a.branch)
                        )}
                        getOptionLabel={(option) =>
                          `${option.name} (${option.branch})`
                        }
                        onChange={(_e, values) => setCollabs(values)}
                        onBlur={handleBlur}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="collaborators"
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                  </div>
                </form>
              </>
            )}
          </Formik>
          <Formik
            initialValues={{ title: "", link: "" }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              const { title, link } = values;
              links.push({
                title: title,
                type: 0,
                value: link,
                isActive: true,
              });
              setLinks(links);
              setSubmitting(false);
              resetForm();
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <form className={classes.root} onSubmit={handleSubmit}>
                <p className="mt-5">Senarai pautan untuk Laman</p>
                <div className="row">
                  <div className="col-12">
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Tajuk</TableCell>
                            <TableCell align="center">Pautan</TableCell>
                            <TableCell align="center">Aktif</TableCell>
                            <TableCell align="center">Menu</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {links.map((link, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">{link.title}</TableCell>
                              <TableCell align="center">{link.value}</TableCell>
                              <TableCell>
                                <Switch
                                  checked={link.isActive}
                                  onClick={() => {
                                    links.isActive = !link.isActive;
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <DeleteIcon
                                  color="action"
                                  onClick={() => {
                                    const filtered = links.filter(
                                      (item) => item.title !== link.title
                                    );
                                    setLinks(filtered);
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div className="col-md-5 col-sm-12">
                    <TextField
                      label="Tajuk"
                      margin="normal"
                      type="text"
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </div>
                  <div className="col-md-5 col-sm-12">
                    <TextField
                      label="Pautan"
                      margin="normal"
                      type="text"
                      name="link"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.link}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </div>
                  <div className="col-md-2 col-sm-12">
                    <Button
                      type="submit"
                      variant="contained"
                      className="btn btn-lg btn-label-brand mt-5"
                    >
                      Tambah
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={success}
            autoHideDuration={6000}
            onClose={handleCloseSuccess}
          >
            <MuiAlert onClose={handleCloseSuccess} severity="success">
              Multilink Successfully Created!
            </MuiAlert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={failure}
            autoHideDuration={6000}
            onClose={handleCloseFailure}
          >
            <MuiAlert onClose={handleCloseFailure} severity="error">
              {error}
            </MuiAlert>
          </Snackbar>
        </PortletBody>
      </Portlet>
    </>
  );
}
