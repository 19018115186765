import axios from "axios";

const token = process.env.REACT_APP_MAIN_API_TOKEN;

const mainClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_MAIN_API_URL
      : process.env.REACT_APP_MAIN_API_URL_LOCAL,
  headers: {
    "X-Request-Agent": "gema-dashboard",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export async function createNewActivity(eventDto) {
  try {
    const response = await mainClient.post("/attendance/activity", eventDto);
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getActivityByUser(id) {
  try {
    const { data } = await mainClient.get(`/attendance/activity/user/${id}`);
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getActivityById(id) {
  try {
    const { data } = await mainClient.get(`/attendance/activity/${id}`);
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getActivityByIdAdmin(id) {
  try {
    const { data } = await mainClient.get(`/attendance/activity/${id}/admin`);
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function updateActivity(id, activityDto) {
  try {
    const response = await mainClient.put(
      `/attendance/activity/${id}`,
      activityDto
    );
    return response.status === 200;
  } catch (error) {
    return false;
  }
}
