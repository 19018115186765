import React from "react";
import { Grid, makeStyles, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import MaterialTable from "material-table";
import EditIcon from "@material-ui/icons/Edit";
import { Image } from "cloudinary-react";
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import { getUrlsByUser } from "../../../../api/multilink";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function MultiLinkList(props) {
  const { id } = useSelector(({ auth }) => auth.user);
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = await getUrlsByUser(id);
        if (data) {
          setRows(data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [id]);

  const columns = [
    {
      field: "id",
      title: "No",
      width: 75,
      render: (rowData) => <p>{rows.indexOf(rowData) + 1}</p>,
    },
    {
      field: "icon",
      title: "Ikon",
      width: 75,
      render: (rowData) => (
        <>
          <Image cloudName="denr13kdi" publicId={rowData.icon} width={50} />
        </>
      ),
    },
    { field: "title", title: "Tajuk Pogram", width: 425 },
    {
      field: "url",
      title: "Pautan",
      render: (rowData) => (
        <>
          <a
            href={`https://sigmagema.com/links/${rowData.url}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {rowData.url}
          </a>
        </>
      ),
    },
    {
      field: "id",
      title: "",
      render: (rowData) => (
        <>
          <Link to={`/app/multilink/edit?id=${rowData._id}`}>
            <EditIcon color="action" />
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      <Portlet>
        <PortletBody fluid={true}>
          <Grid className="p-3" container spacing={3}>
            <Grid item xs={12}>
              <span className="kt-section__sub">
                Senarai pautan yang telah dipendekkan
              </span>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.root}>
                <MaterialTable
                  columns={columns}
                  data={rows}
                  title={`Bilangan pautan: ${rows.length}`}
                />
              </Paper>
            </Grid>
          </Grid>
        </PortletBody>
      </Portlet>
    </>
  );
}
