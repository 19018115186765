import axios from "axios";

const token = process.env.REACT_APP_MAIN_API_TOKEN;

const mainClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_MAIN_API_URL
      : process.env.REACT_APP_MAIN_API_URL_LOCAL,
  headers: {
    "X-Request-Agent": "gema-dashboard",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export async function getAllEvent() {
  try {
    const { data } = await mainClient.get("/event");
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getEventByStatus(qs, br) {
  try {
    const { data } = await mainClient.get(`/event?status=${qs}&branch=${br}`);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getEventById(param) {
  try {
    const { data } = await mainClient.get(`/event/${param}`);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getEventWithEnrollmentsById(param) {
  try {
    const { data } = await mainClient.get(`/event/${param}/enrollments`);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getEventSheetsById(param) {
  try {
    const { data } = await mainClient.get(`/event/${param}/sheet`);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function updateEvent(id, values) {
  try {
    const eventDto = {
      ...values,
      eligibility: values.eventEligibility,
    };
    const response = await mainClient.put(`/event/${id}`, eventDto);
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (error) {
    throw error.response.data;
  }
}

export async function createNewEvent(eventDto) {
  try {
    const response = await mainClient.post("/event", eventDto);
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (error) {
    throw error.response.data;
  }
}
