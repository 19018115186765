import React from "react";
import { Formik } from "formik";
import {
  TextField,
  Snackbar,
  CircularProgress,
  Button
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../../partials/content/Portlet";
import { branch, role } from "../../../../utils/constant";
import { createUser } from "../../../../api/auth";

export default function NewUserPage(props) {
  const [success, setSuccess] = React.useState(false);
  const [failure, setFailure] = React.useState(false);
  const [error, setError] = React.useState("Registartion Failed!");

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess(false);
  };
  const handleCloseFailure = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFailure(false);
  };

  return (
    <>
      <Portlet>
        <PortletHeader title="Daftar Pengguna baru" />
        <PortletBody fluid={true}>
          <Formik
            initialValues={{}}
            onSubmit={(values, { setSubmitting }) => {
              createUser(values)
                .then(() => {
                  setSuccess(true);
                  setSubmitting(false);
                })
                .catch((err) => {
                  setError(err.message || "Failed");
                  setFailure(true);
                  setSubmitting(false);
                });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <TextField
                      label="E-mel"
                      margin="normal"
                      type="text"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </div>

                  <div className="col-12">
                    <Autocomplete
                      options={branch}
                      fullWidth
                      getOptionLabel={(option) => option.title}
                      onChange={(_e, val) =>
                        setFieldValue("branch", val ? val.value : undefined)
                      }
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Cawangan Gema"
                          margin="normal"
                          variant="outlined"
                          value={values.branch}
                          required
                        />
                      )}
                    />
                  </div>

                  <div className="col-md-12">
                    <Autocomplete
                      options={role}
                      fullWidth
                      getOptionLabel={(option) => option.title}
                      onChange={(_e, val) =>
                        setFieldValue("userType", val ? val.value : undefined)
                      }
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Jawatan"
                          margin="normal"
                          variant="outlined"
                          value={values.userType}
                          required
                        />
                      )}
                    />
                  </div>

                  <div className="col-md-12 col-sm-12 p-3">
                    {!isSubmitting ? (
                      <Button
                        type="submit"
                        variant="contained"
                        className="btn btn-lg btn-elevate btn-label-brand"
                      >
                        Tambah Penguna
                      </Button>
                    ) : (
                      <CircularProgress />
                    )}
                  </div>
                </div>
              </form>
            )}
          </Formik>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={success}
            autoHideDuration={6000}
            onClose={handleCloseSuccess}
          >
            <MuiAlert onClose={handleCloseSuccess} severity="success">
              Invitation Sent!
            </MuiAlert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={failure}
            autoHideDuration={6000}
            onClose={handleCloseFailure}
          >
            <MuiAlert onClose={handleCloseFailure} severity="error">
              {error}
            </MuiAlert>
          </Snackbar>
        </PortletBody>
      </Portlet>
    </>
  );
}
