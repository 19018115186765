import React from "react";
import { Route, Switch } from "react-router-dom";
import NewMemberPage from "./NewMemberPage";
import EditMemberPage from "./EditMemberPage";
import MemberListPage from "./ListMemberPage";
import MemberExpired from "./MemberExpired";

export default function MemberPage() {
  return (
    <Switch>
      <Route path="/member/new" component={NewMemberPage} />
      <Route path="/member/edit" component={EditMemberPage} />
      <Route path="/member/list" component={MemberListPage} />
      <Route path="/member/expired" component={MemberExpired} />
    </Switch>
  );
}
