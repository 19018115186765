import React from "react";
import { Grid, makeStyles, Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import { getExpiredMembers } from "../../../api/member";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function MemberExpired(props) {
  const classes = useStyles();
  const [expired, setExpired] = React.useState([]);
  const [alumni, setAlumni] = React.useState([]);
  const [msg, setMsg] = React.useState("");

  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = await getExpiredMembers();
        if (data) {
          if (data.message) {
            setMsg("Tiada Ahli yang tamat keahlian hari ini");
          }
          setExpired(data.expired);
          setAlumni(data.alumni);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const generateColumns = (rows) => {
    return [
      {
        field: "id",
        title: "No",
        width: 50,
        render: (rowData) => <p>{rows.indexOf(rowData) + 1}</p>,
      },
      { field: "fullname", title: "Nama", width: 250 },
      { field: "email", title: "Emel", width: 250 },
      { field: "endDate", title: "Tarikh Tamat", width: 250 },
    ];
  };

  return (
    <>
      {msg ? (
        <>
          <h3 style={{ textAlign: "center" }}>{msg}</h3>
        </>
      ) : (
        <>
          {[alumni, expired].map((rows, index) => (
            <Portlet>
              <PortletBody fluid={true}>
                <Grid className="p-3" container spacing={3}>
                  <Grid item xs={12}>
                    <span className="kt-section__sub">
                      {index === 0
                        ? "Senarai ahli yang telah menjadi alumni untuk hari ini."
                        : "Senarai ahli yang telah tamat keahlian untuk hari ini."}
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper className={classes.root}>
                      <MaterialTable
                        columns={generateColumns(rows)}
                        data={rows}
                        title={
                          index === 0
                            ? `Bilangan Ahli Alumni: ${rows.length}`
                            : `Bilangan Ahli Tamat Tempoh: ${rows.length}`
                        }
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </PortletBody>
            </Portlet>
          ))}
        </>
      )}
    </>
  );
}
