import axios from "axios";

const urlAxios = axios.create({
  baseURL: process.env.REACT_APP_SHORTURL_SERVICE_URL,
});

export async function createUrl(urlDto) {
  try {
    const { data } = await urlAxios.post(`/new`, urlDto);
    return data;
  } catch (error) {
    const { response } = error;
    if (response) {
      throw new Error(response.data.message);
    }
    throw new Error(error);
  }
}

export async function getUrlsByUser(id) {
  try {
    const { data } = await urlAxios.get(`/user/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function updateUrl(id, urlDto) {
  try {
    const response = await urlAxios.put(`/${id}`, urlDto);
    return response.status === 200;
  } catch (error) {
    console.log(error);
  }
}

export async function removeUrl(id) {
  try {
    const response = await urlAxios.delete(`/${id}`);
    return response.status === 200;
  } catch (error) {
    console.log(error);
  }
}
