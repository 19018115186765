import React from "react";
import Alert from "@material-ui/lab/Alert";
import { useSelector } from 'react-redux';
import CircularStatsChart from "../../../widgets/CircularStatsChart";
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import { getReportData } from "../../../api/report";

export default function ChartsPage(props) {
  const [chartData, setChartData] = React.useState({
    branch: [],
    memberType: [],
    memberStatus: [],
    race: [],
    agama: [],
    gender: [],
  });
  const user = useSelector(({ auth }) => auth.user);
  const br = user.userType === 0 ? undefined : user.branch;
  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = await getReportData(br);
        if (data) {
          setChartData(data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [br]);

  const branch = {
    datasets: [
      {
        data: chartData.branch,
        backgroundColor: [
          "#162726",
          "#5C745C",
          "#F5DD95",
          "#A23029",
          "#F2B491",
          "#E0B99A",
          "#999C86",
          "#44524E",
          "#262026",
          "#7AABB7",
          "#8AD1C2",
          "#A1BFA6",
          "#F5D39F",
        ],
      },
    ],
    labels: [
      `PERLIS - ${chartData.branch[0]}`,
      `KEDAH - ${chartData.branch[1]}`,
      `PULAU PINANG - ${chartData.branch[2]}`,
      `PERAK - ${chartData.branch[3]}`,
      `SELANGOR - ${chartData.branch[4]}`,
      `KUALA LUMPUR - ${chartData.branch[5]}`,
      `NEGERI SEMBILAN - ${chartData.branch[6]}`,
      `MELAKA - ${chartData.branch[7]}`,
      `JOHOR - ${chartData.branch[8]}`,
      `PAHANG - ${chartData.branch[9]}`,
      `TERENGGANU - ${chartData.branch[10]}`,
      `KELANTAN - ${chartData.branch[11]}`,
    ],
  };

  const memberType = {
    datasets: [
      {
        data: chartData.memberType,
        backgroundColor: ["#25BDCB", "#484559"],
      },
    ],
    labels: [`ASAS - ${chartData.memberType[0]}`, `PENUH - ${chartData.memberType[1]}`],
  };

  const memberStatus = {
    datasets: [
      {
        data: chartData.memberStatus,
        backgroundColor: [
          "#23383D",
          "#827D5B",
          "#CCB06B",
          "#EED361",
          "#FCE6B7",
        ],
      },
    ],
    labels: [`BELUM BAYAR - ${chartData.memberStatus[0]}`, `BARU - ${chartData.memberStatus[1]}`, `AKTIF - ${chartData.memberStatus[2]}`, `ALUMNI - ${chartData.memberStatus[3]}`, `TIDAK LULUS - ${chartData.memberStatus[4]}`, `TAMAT TEMPOH - ${chartData.memberStatus[5]}`],
  };

  const race = {
    datasets: [
      {
        data: chartData.race,
        backgroundColor: [
          "#366979",
          "#D6AA60",
          "#8D855D",
          "#954B41",
          "#38413F",
          "#18504F",
          "#366979",
          "#BAD09C",
          "#C5A84E",
        ],
      },
    ],
    labels: [
      `MELAYU - ${chartData.race[0]}`,
      `CINA - ${chartData.race[1]}`,
      `INDIA - ${chartData.race[2]}`,
      `IBAN - ${chartData.race[3]}`,
      `BUGIS - ${chartData.race[4]}`,
      `KADAZANDUSUN - ${chartData.race[5]}`,
      `BAJAU - ${chartData.race[6]}`,
      `MURUT - ${chartData.race[7]}`,
      `LAIN-LAIN - ${chartData.race[8]}`,
    ],
  };

  const agama = {
    datasets: [
      {
        data: chartData.agama,
        backgroundColor: [
          "#D85D3D",
          "#F1E561",
          "#DA986C",
          "#C64F3A",
          "#915439",
          "#452321",
        ],
      },
    ],
    labels: [`ISLAM - ${chartData.agama[0]}`, `BUDDHA - ${chartData.agama[1]}`, `KRISTIAN - ${chartData.agama[2]}`, `HINDU - ${chartData.agama[3]}`, `SIKH - ${chartData.agama[4]}`, `LAIN-LAIN - ${chartData.agama[5]}`],
  };

  const gender = {
    datasets: [
      {
        data: chartData.gender,
        backgroundColor: ["#396C7E", "#353231"],
      },
    ],
    labels: [`PEREMPUAN - ${chartData.gender[0]}`, `LELAKI - ${chartData.gender[1]}`],
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="m-2">
            {chartData.branch.length === 0 && (
              <Alert severity="warning">Please wait. Chart is loading!</Alert>
            )}
          </div>
          <div className="row row-full-height">
            <div className="col-12">
              <Portlet>
                <PortletBody fluid={true}>
                  <CircularStatsChart title="Cawangan" datasets={branch} />
                </PortletBody>
              </Portlet>

              <div className="kt-space-20" />

              <Portlet>
                <PortletBody fluid={true}>
                  <CircularStatsChart
                    title="Status Keahlian"
                    datasets={memberType}
                  />
                </PortletBody>
              </Portlet>
            </div>

            <div className="col-12">
              <Portlet>
                <PortletBody fluid={true}>
                  <CircularStatsChart
                    title="Jenis Keahlian"
                    datasets={memberStatus}
                  />
                </PortletBody>
              </Portlet>

              <div className="kt-space-20" />

              <Portlet>
                <PortletBody fluid={true}>
                  <CircularStatsChart title="Bangsa" datasets={race} />
                </PortletBody>
              </Portlet>
            </div>

            <div className="col-12">
              <Portlet>
                <PortletBody fluid={true}>
                  <CircularStatsChart title="Agama" datasets={agama} />
                </PortletBody>
              </Portlet>

              <div className="kt-space-20" />

              <Portlet>
                <PortletBody fluid={true}>
                  <CircularStatsChart title="Jantina" datasets={gender} />
                </PortletBody>
              </Portlet>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
