import React from "react";
import { Link } from "react-router-dom";
import { Grid, makeStyles, Paper, Chip } from "@material-ui/core";
import { useSelector } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import LinkTwoTone from "@material-ui/icons/LinkTwoTone";
import PersonIcon from "@material-ui/icons/Person";
import MaterialTable from "material-table";
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import * as qs from "query-string";
import exportToExcel from "../../../utils/exportToExcel";
import { getEventByStatus } from "../../../api/event";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

export default function ListEventPage(props) {
  const { userType, branch } = useSelector(({ auth }) => auth.user);
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const br = userType === 0 ? undefined : branch;

  React.useEffect(() => {
    async function fetchData() {
      try {
        const parsed = qs.parse(props.location.search);
        const data = await getEventByStatus(
          parsed.staus !== "undefined" ? parsed.status : undefined,
          br
        );
        if (data) {
          setRows(data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [props.location.search, br]);

  const getEventStatus = (status) => {
    switch (status) {
      case 0:
        return "Baru";
      case 1:
        return "AKtif";
      case 2:
        return "Tamat";
      case 3:
        return "Tutup";
      default:
        return "Baru";
    }
  };

  const columns = [
    {
      field: "id",
      title: "No",
      width: 50,
      render: (rowData) => <p>{rows.indexOf(rowData) + 1}</p>,
    },
    { field: "name", title: "Nama", width: 400 },
    { field: "venue", title: "Tempat" },
    {
      field: "eventStatus",
      title: "Status",
      render: (rowData) => (
        <Chip label={getEventStatus(rowData.eventStatus)} color="primary" />
      ),
    },
    {
      field: "id",
      title: "Peserta",
      render: (rowData) => (
        <div className="text-center">
          <Link to={`/event/view?id=${rowData.id}`}>
            <PersonIcon color="action" />
          </Link>
        </div>
      ),
    },
    {
      field: "eventId",
      title: "Pautan",
      render: (rowData) => (
        <>
          <a
            href={`https://sigmagema.com/event?id=${rowData.eventId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkTwoTone color="action" />
          </a>
        </>
      ),
    },
    {
      field: "id",
      title: "",
      render: (rowData) => (
        <div className="text-center">
          <Link to={`/event/edit?id=${rowData.id}`}>
            <EditIcon color="action" />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <Portlet>
      <PortletBody fluid={true}>
        <Grid className="p-3" container spacing={3}>
          <Grid item xs={12}>
            <span className="kt-section__sub">
              Senarai di bawah menrangkumi peristiwa yang telah dicatat melalui
              sistem sigma.
            </span>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.root}>
              <MaterialTable
                columns={columns}
                data={rows}
                title={`Bilangan peristiwa: ${rows.length}`}
                options={{
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportCsv: (columns, data) => {
                    const st = data.map((member) => {
                      return {
                        No_Ahli: member.membershipId,
                        Cawangan: member.branch,
                        Nama: member.fullname,
                        No_IC: member.nationalId,
                        Umur: member.age,
                        No_Telefon: member.phone,
                        Emel: member.email,
                        Alamat: member.address,
                        Bandar: member.city,
                        Jantina: member.gender,
                        Pekerjaan: member.occupation,
                        Pendidikan: member.education,
                        Agama: member.religion,
                        Bangsa: member.bangsa,
                        Tarik_Mula: member.startDate,
                        Tarikh_Tamat: member.endDate,
                      };
                    });
                    exportToExcel(st);
                  },
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </PortletBody>
    </Portlet>
  );
}
