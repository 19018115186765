import React from "react";
import { Formik, FieldArray } from "formik";
import {
  TextField,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormControl,
  Radio,
  RadioGroup,
  Snackbar,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import * as qs from "query-string";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../partials/content/Portlet";
import {
  interests,
  branch,
  states,
  religions,
  races,
  genders,
  education,
} from "../../../utils/constant";
import {
  getMembersById,
  updateMember,
  approve,
  reject,
} from "../../../api/member";
import moment from "moment";
import { connect } from "react-redux";

function EditMemberPage(props) {
  const [refresh, setRefresh] = React.useState(false);
  const [details, setDetails] = React.useState({
    id: "",
    membershipId: 0,
    nationalId: "",
    startDate: "",
    endDate: "",
    fullname: "",
    branch: 0,
    age: 0,
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    postcode: "",
    education: "",
    occupation: "",
    employerAddress: "",
    race: "",
    religion: "",
    gender: "",
    interest: [],
    memberType: 0,
    memberStatus: 0,
    introcuderName: "",
    introducerContact: null,
    fbProfile: "",
  });
  const [success, setSuccess] = React.useState(false);
  const [failure, setFailure] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [remarks, setRemaks] = React.useState("");
  const [error, setError] = React.useState("Registartion Failed!");
  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess(false);
  };
  const handleCloseFailure = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFailure(false);
  };

  const handleApprove = async () => {
    const updated = await approve(details.id, props.user.name);
    if (updated) {
      return setRefresh(true);
    }
    return;
  };

  const handleReject = async () => {
    const updated = await reject(details.id, remarks, props.user.name);
    if (updated) {
      setOpenDialog(false);
      return setRefresh(true);
    }
    return;
  };

  const getMemberStatus = (status) => {
    switch (status) {
      case 0:
        return "Belum Bayar";
      case 1:
        return "Baru";
      case 2:
        return "Aktif";
      case 3:
        return "Alumni";
      case 4:
        return "Tidak diluluskan";
      case 5:
        return "Tamat Tempoh";
      default:
        return "Tiada Maklumat";
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      try {
        const parsed = qs.parse(props.location.search);
        const data = await getMembersById(parsed.id);
        if (data) {
          setDetails({
            ...data,
            introcuderName: data.introducer ? data.introducer.name : null,
            introducerContact: data.introducer ? data.introducer.phone : null,
          });
          setRefresh(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [props.location.search, refresh]);

  console.log(details);
  return (
    <Portlet>
      <PortletHeader title="Maklumat Ahli" />
      <PortletBody fluid={true}>
        <Formik
          enableReinitialize={true}
          onReset={(values) => {}}
          style={{ maxWidth: 400 }}
          initialValues={{
            ...details,
          }}
          onSubmit={(values, { setSubmitting }) => {
            updateMember(details.id, JSON.stringify(values, null, 2))
              .then(() => {
                setSuccess(true);
                setSubmitting(false);
              })
              .catch((err) => {
                setError(err.message || "Failed");
                setFailure(true);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <FormLabel component="legend">Status keahlian</FormLabel>
                  <p>{getMemberStatus(values.memberStatus)}</p>
                </div>
                <div className="col-md-6 col-sm-12">
                  <FormLabel component="legend">Tempoh Keahlian</FormLabel>
                  <p>
                    {values.startDate &&
                      `${moment
                        .utc(values.startDate)
                        .format("Do MMMM YYYY")} - ${moment
                        .utc(values.endDate)
                        .format("Do MMMM YYYY")}`}
                  </p>
                </div>
                <div className="col-md-12 col-sm-12">
                  <TextField
                    label="Nama Penuh"
                    margin="normal"
                    type="text"
                    name="fullname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.fullname}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <TextField
                    label="No. Kad Pengenalan"
                    margin="normal"
                    type="number"
                    name="nationalId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nationalId}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <TextField
                    label="Umur"
                    margin="normal"
                    fullWidth
                    type="number"
                    name="age"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.age}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <Autocomplete
                    options={branch}
                    getOptionLabel={(option) => option.title}
                    value={branch[values.branch || 0]}
                    onChange={(_e, val) =>
                      setFieldValue("branch", val ? val.value : 0)
                    }
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cawangan Gema"
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <TextField
                    label="Email"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <TextField
                    label="No HP"
                    margin="normal"
                    fullWidth
                    type="number"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <TextField
                    label="Alamat surat menyurat"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.address}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <TextField
                    label="Bandar"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.city}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <Autocomplete
                    options={states}
                    getOptionLabel={(option) => option}
                    value={values.state}
                    onChange={(_e, val) => setFieldValue("state", val)}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Negeri"
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <TextField
                    label="Poskod"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="postcode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.postcode}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <Autocomplete
                    value={values.education}
                    options={education}
                    getOptionLabel={(option) => option}
                    onChange={(_e, val) => {
                      setFieldValue("education", val);
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Taraf Pendidikan"
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <TextField
                    label="Pekerjaan"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="occupation"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.occupation}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-md-12 col-sm-12">
                  <TextField
                    label="Alamat majikan"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="employerAddress"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.employerAddress}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <Autocomplete
                    value={values.race}
                    options={races}
                    getOptionLabel={(option) => option}
                    onChange={(_e, val) => {
                      setFieldValue("race", val);
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Bangsa"
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <Autocomplete
                    options={religions}
                    getOptionLabel={(option) => option}
                    value={values.religion}
                    onChange={(_e, val) => {
                      setFieldValue("religion", val);
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Agama"
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <Autocomplete
                    value={values.gender}
                    options={genders}
                    getOptionLabel={(option) => option}
                    name="gender"
                    onChange={(_e, val) => {
                      setFieldValue("gender", val);
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Jantina"
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <TextField
                    label="Nama rakan Ahli Gema"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="introcuderName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.introcuderName}
                    variant="outlined"
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <TextField
                    label="No Telefon rakan Ahli Gema"
                    margin="normal"
                    fullWidth
                    type="number"
                    name="introducerContact"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.introducerContact}
                    variant="outlined"
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <TextField
                    label="Profil Facebook"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="fbProfile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.fbProfile}
                    variant="outlined"
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <FormLabel component="legend">
                    Sila pilih minat anda *
                  </FormLabel>
                  <FieldArray
                    name="interest"
                    render={(arrayHelpers) => (
                      <div>
                        {interests.map((tag) => (
                          <div key={tag}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="int"
                                  value={tag}
                                  checked={values.interest.includes(tag)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      arrayHelpers.push(tag);
                                    } else {
                                      const idx = values.interest.indexOf(tag);
                                      arrayHelpers.remove(idx);
                                    }
                                  }}
                                />
                              }
                              label={tag}
                            />
                            <br />
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <FormLabel component="legend">Jenis Keahlian</FormLabel>
                  <p>
                    Yuran Asas: RM10 perlu dibayar secara tahunan. <br /> Yuran
                    Penuh: RM55 dibayar sekali sehingga tamat keahlian.
                  </p>
                  <p>Saya berminat untuk menjadi ahli:</p>
                  <FormControl>
                    <RadioGroup
                      aria-label="gender"
                      name="memberType"
                      value={values.memberType}
                    >
                      <FormControlLabel
                        value={0}
                        control={<Radio color="primary" />}
                        label="Yuran Asas"
                        onChange={() => setFieldValue("memberType", 0)}
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio color="primary" />}
                        label="Yuran Penuh"
                        onChange={() => setFieldValue("memberType", 1)}
                      />
                    </RadioGroup>
                  </FormControl>
                  <lottie-player
                    src="https://assets8.lottiefiles.com/datafiles/6WfDdm3ooQTEs1L/data.json"
                    background="transparent"
                    speed="3"
                    style={{ width: 400, height: 400 }}
                    loop
                    autoplay
                  />
                </div>
                <div className="col-md-12 col-sm-12 text-right">
                  {!isSubmitting ? (
                    <>
                      {details.memberStatus === 0 ||
                      details.memberStatus === 1 ? (
                        <>
                          {props.user.userType === 0 ? (
                            <>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleApprove}
                              >
                                <CheckIcon style={{ marginRight: 2 }} />
                                Lulus Permohonan
                              </Button>
                              <span style={{ padding: 10 }}> atau </span>
                              <Button
                                variant="contained"
                                className="btn btn-lg btn-elevate btn-danger"
                                onClick={() => setOpenDialog(true)}
                              >
                                <ClearIcon style={{ marginRight: 2 }} />
                                Tolak Permohonan
                              </Button>
                            </>
                          ) : (
                            <>
                              <br />
                              <p style={{ color: "red" }}>
                                Keahlian belum diluluskan oleh pihak pentadbir
                              </p>
                            </>
                          )}
                        </>
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          className="btn btn-lg btn-elevate btn-label-brand"
                        >
                          Kemaskini Maklumat
                        </Button>
                      )}
                    </>
                  ) : (
                    <CircularProgress />
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={success}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
        >
          <MuiAlert onClose={handleCloseSuccess} severity="success">
            Information successfully added!
          </MuiAlert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={failure}
          autoHideDuration={6000}
          onClose={handleCloseFailure}
        >
          <MuiAlert onClose={handleCloseFailure} severity="error">
            {error}
          </MuiAlert>
        </Snackbar>
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Kenapa permohonan ini ditolak?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                fullWidth
                multiline
                rows={4}
                defaultValue=""
                variant="outlined"
                onChange={(event) => setRemaks(event.target.value || "")}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Batal
            </Button>
            <Button onClick={handleReject} color="primary" autoFocus>
              Tolak
            </Button>
          </DialogActions>
        </Dialog>
      </PortletBody>
    </Portlet>
  );
}

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(EditMemberPage);
