import React from "react";
import { Formik } from "formik";
import { TextField, Button, Snackbar } from "@material-ui/core";
import { branch } from "../../../utils/constant";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../partials/content/Portlet";
import { updateAssociate, getAssociateById } from "../../../api/associate";
import * as qs from "query-string";
import MuiAlert from "@material-ui/lab/Alert";

export default function EditAssociatePage(props) {
  const [refresh, setRefresh] = React.useState(false);
  const [details, setDetails] = React.useState({
    associateId: 0,
    branch: 0,
    email: "",
    fbProfile: "",
    fullname: "",
    iGProfile: "",
    id: "",
    nationalId: "",
    phone: "",
    tikTokProfile: "",
  });
  const [success, setSuccess] = React.useState(false);
  const [failure, setFailure] = React.useState(false);
  const [error, setError] = React.useState("Registartion Failed!");

  React.useEffect(() => {
    async function fetchData() {
      try {
        const parsed = qs.parse(props.location.search);
        const data = await getAssociateById(parsed.id);
        if (data) {
          setDetails(data);
          setRefresh(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [props.location.search, refresh]);

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess(false);
  };
  const handleCloseFailure = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFailure(false);
  };

  return (
    <Portlet>
      <PortletHeader title="Rakan Gema" />
      <PortletBody fluid={true}>
        <Formik
          enableReinitialize={true}
          onReset={(values) => {}}
          style={{ maxWidth: 400 }}
          initialValues={{
            ...details,
          }}
          onSubmit={(values, { setSubmitting }) => {
            updateAssociate(details.id, JSON.stringify(values, null, 2))
              .then(() => {
                setSuccess(true);
                setSubmitting(false);
              })
              .catch((err) => {
                setError(err.message || "Failed");
                setFailure(true);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 offset-md-3 col-sm-12">
                  <TextField
                    label="Nama Penuh"
                    margin="normal"
                    type="text"
                    name="fullname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.fullname}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </div>
                <div className="col-md-6 offset-md-3 col-sm-12">
                  <TextField
                    label="No. Kad Pengenalan"
                    margin="normal"
                    type="number"
                    name="nationalId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nationalId}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </div>
                <div className="col-md-6 offset-md-3 col-sm-12">
                  <Autocomplete
                    options={branch}
                    getOptionLabel={(option) => option.title}
                    defaultValue={branch[values.branch].title}
                    onChange={(_e, val) =>
                      setFieldValue("branch", val ? val.value : undefined)
                    }
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cawangan Gema"
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="col-md-6 offset-md-3 col-sm-12">
                  <TextField
                    label="Email"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-md-6 offset-md-3 col-sm-12">
                  <TextField
                    label="No HP"
                    margin="normal"
                    fullWidth
                    type="number"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-md-6 offset-md-3 col-sm-12">
                  <TextField
                    label="Profil Facebook"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="fbProfile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.fbProfile}
                    variant="outlined"
                  />
                </div>
                <div className="col-md-6 offset-md-3 col-sm-12">
                  <TextField
                    label="Profil Instagram"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="iGProfile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.iGProfile}
                    variant="outlined"
                  />
                </div>
                <div className="col-md-6 offset-md-3 col-sm-12">
                  <TextField
                    label="Profil Tiktok"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="tikTokProfile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.tikTokProfile}
                    variant="outlined"
                  />
                </div>
              </div>
              <Button
                type="submit"
                variant="contained"
                className="btn btn-lg btn-elevate btn-label-brand"
              >
                Kemaskini Maklumat
              </Button>
            </form>
          )}
        </Formik>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={success}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
        >
          <MuiAlert onClose={handleCloseSuccess} severity="success">
            Information successfully added!
          </MuiAlert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={failure}
          autoHideDuration={6000}
          onClose={handleCloseFailure}
        >
          <MuiAlert onClose={handleCloseFailure} severity="error">
            {error}
          </MuiAlert>
        </Snackbar>
      </PortletBody>
    </Portlet>
  );
}
