import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ChartsPage from "./Charts";
import AdminsPage from "./users/Admins";
import StaffsPage from "./users/Staffs";
import ManagerPage from "./users/Managers";
import NewUserPage from "./users/New";
import ActivityPage from "./Activity";

export default function ManagePage() {
  return (
    <Switch>
      <Route path="/manage/users/admins" component={AdminsPage} />
      <Route path="/manage/users/staffs" component={StaffsPage} />
      <Route path="/manage/users/managers" component={ManagerPage} />
      <Route path="/manage/users/new" component={NewUserPage} />
      <Route path="/manage/charts" component={ChartsPage} />
      <Route path="/manage/activity" component={ActivityPage} />
      <Redirect to="/error/error1" />
    </Switch>
  );
}
