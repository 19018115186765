import React from "react";
import { Formik, FieldArray } from "formik";
import {
  TextField,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormControl,
  Radio,
  RadioGroup,
  Snackbar,
  CircularProgress,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../partials/content/Portlet";
import {
  interests,
  branch,
  states,
  religions,
  races,
  genders,
  education,
} from "../../../utils/constant";
import { createNewMember } from "../../../api/member";

export default function NewMemberPage() {
  const [success, setSuccess] = React.useState(false);
  const [failure, setFailure] = React.useState(false);
  const [error, setError] = React.useState("Registartion Failed!");
  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess(false);
  };
  const handleCloseFailure = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFailure(false);
  };

  return (
    <Portlet>
      <PortletHeader title="Maklumat Ahli" />
      <PortletBody fluid={true}>
        <Formik
          style={{ maxWidth: 400 }}
          initialValues={{
            interest: [],
            memberType: 0,
          }}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values);
            createNewMember(JSON.stringify(values, null, 2))
              .then(() => {
                setSuccess(true);
                setSubmitting(false);
              })
              .catch((err) => {
                setError(err.message || "Failed");
                setFailure(true);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row p-3">
                <div className="col-md-12 col-sm-12">
                  <TextField
                    label="Nama Penuh"
                    margin="normal"
                    type="text"
                    name="fullname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.fullname}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <TextField
                    label="No. Kad Pengenalan"
                    margin="normal"
                    type="number"
                    name="nationalId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nationalId}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <TextField
                    label="Umur"
                    margin="normal"
                    fullWidth
                    type="number"
                    name="age"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.age}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <Autocomplete
                    options={branch}
                    getOptionLabel={(option) => option.title}
                    onChange={(_e, val) =>
                      setFieldValue("branch", val ? val.value : undefined)
                    }
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cawangan Gema"
                        margin="normal"
                        variant="outlined"
                        value={values.branch}
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <TextField
                    label="Email"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <TextField
                    label="No HP"
                    margin="normal"
                    fullWidth
                    type="number"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <TextField
                    label="Alamat surat menyurat"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.address}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <TextField
                    label="Bandar"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.city}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <Autocomplete
                    options={states}
                    getOptionLabel={(option) => option}
                    onChange={(_e, val) => setFieldValue("state", val)}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Negeri"
                        margin="normal"
                        variant="outlined"
                        value={values.state}
                        fullWidth
                        required
                      />
                    )}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <TextField
                    label="Poskod"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="postcode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.postcode}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <Autocomplete
                    options={education}
                    getOptionLabel={(option) => option}
                    onChange={(_e, val) => {
                      setFieldValue("education", val);
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Taraf Pendidikan"
                        margin="normal"
                        variant="outlined"
                        value={values.education}
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <TextField
                    label="Pekerjaan"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="occupation"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.occupation}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-md-12 col-sm-12">
                  <TextField
                    label="Alamat majikan"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="employerAddress"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.employerAddress}
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <Autocomplete
                    options={races}
                    getOptionLabel={(option) => option}
                    onChange={(_e, val) => {
                      setFieldValue("race", val);
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Bangsa"
                        margin="normal"
                        variant="outlined"
                        value={values.race}
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <Autocomplete
                    options={religions}
                    getOptionLabel={(option) => option}
                    onChange={(_e, val) => {
                      setFieldValue("religion", val);
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Agama"
                        margin="normal"
                        variant="outlined"
                        value={values.religion}
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <Autocomplete
                    options={genders}
                    getOptionLabel={(option) => option}
                    name="gender"
                    onChange={(_e, val) => {
                      setFieldValue("gender", val);
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Jantina"
                        margin="normal"
                        variant="outlined"
                        value={values.gender}
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <TextField
                    label="Nama rakan Ahli Gema"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="introcuderName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.introcuderName}
                    variant="outlined"
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <TextField
                    label="No Telefon rakan Ahli Gema"
                    margin="normal"
                    fullWidth
                    type="number"
                    name="introducerContact"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.introducerContact}
                    variant="outlined"
                  />
                </div>
                <div className="col-md-4 col-sm-12">
                  <TextField
                    label="Profil Facebook"
                    margin="normal"
                    fullWidth
                    type="text"
                    name="fbProfile"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.fbProfile}
                    variant="outlined"
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <FormLabel component="legend">
                    Sila pilih minat anda *
                  </FormLabel>
                  <FieldArray
                    name="interest"
                    render={(arrayHelpers) => (
                      <div>
                        {interests.map((tag) => (
                          <div key={tag}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="int"
                                  value={tag}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      arrayHelpers.push(tag);
                                    } else {
                                      const idx = values.interest.indexOf(tag);
                                      arrayHelpers.remove(idx);
                                    }
                                  }}
                                />
                              }
                              label={tag}
                            />
                            <br />
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <FormLabel component="legend">Jenis Keahlian</FormLabel>
                  <p>
                    Yuran Asas: RM10 perlu dibayar secara tahunan. <br /> Yuran
                    Penuh: RM55 dibayar sekali sehingga tamat keahlian.
                  </p>
                  <p>Saya berminat untuk menjadi ahli:</p>
                  <FormControl>
                    <RadioGroup
                      aria-label="gender"
                      name="memberType"
                      value={values.memberType}
                    >
                      <FormControlLabel
                        value={0}
                        control={<Radio color="primary" />}
                        label="Yuran Asas"
                        onChange={() => setFieldValue("memberType", 0)}
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio color="primary" />}
                        label="Yuran Penuh"
                        onChange={() => setFieldValue("memberType", 1)}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="col-md-12 col-sm-12 text-center">
                  {!isSubmitting ? (
                    <Button
                      type="submit"
                      variant="contained"
                      className="btn btn-lg btn-elevate btn-label-brand"
                    >
                      Hantar Permohonan
                    </Button>
                  ) : (
                    <CircularProgress />
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={success}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
        >
          <MuiAlert onClose={handleCloseSuccess} severity="success">
            Registration Success!
          </MuiAlert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={failure}
          autoHideDuration={6000}
          onClose={handleCloseFailure}
        >
          <MuiAlert onClose={handleCloseFailure} severity="error">
            {error}
          </MuiAlert>
        </Snackbar>
      </PortletBody>
    </Portlet>
  );
}
